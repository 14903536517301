import React, { useState } from "react";
import { Wrapper } from "../Style";
import { Button, Form, Space, Table } from "antd";
import { RenderInput } from "../../../components/common/FormField";
import { useDispatch } from "react-redux";
import {
  deleteFaq,
  getFaqList,
} from "../../../Redux/ContentManagementSlice/action";
import { usePaginationTable } from "../../../components/common/Table";
import { debounce } from "../../../utils/utilFunctions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import dayjs from "dayjs";
import { DeleteIcon, EditIcon } from "../../../svg";
import { toastMessage } from "../../../components/common/ToastMessage";
import ConfirmModal from "../../../components/common/Modal/ConfirmModal";

const Faqs = () => {
  const [searchInput, setSearchInput] = useState("");
  const [faqId, setFaqId] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchData = async (requestArgs) => {
    const { current, pageSize, sortBy, sortOrder, search } = requestArgs;

    let params = `?search=${search || ""}&page=${current || 1}&size=${
      pageSize || 10
    }&sort_by=${sortBy ? `${sortBy}` : ""}&sort_order=${sortOrder || ""}`;

    const response = await dispatch(getFaqList(params)).catch((err) =>
      console.error(err)
    );
    return { data: response?.data?.list, total: response?.data?.total_count };
  };

  const { handleTableChange, data, loading, pagination, refetch } =
    usePaginationTable(fetchData, searchInput);

  const showModal = (record) => {
    setIsModalOpen(true);
    setFaqId(record?.id);
  };

  const deleteFaqFun = () => {
    dispatch(deleteFaq(faqId))
      .then(() => {
        refetch();
        toastMessage("success", "FAQs deleted successfully");
      })
      .catch((err) => console.error(err));
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const debouncedSearchHandler = debounce(handleSearchChange, 300);

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      width: "30%",
      // sorter: (a, b) => a?.question - b?.question,
      render: (row, record) => {
        return <div>{record?.question}</div>;
      },
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      width: "40%",
      //   sorter: (a, b) => a?.name - b?.name,
      render: (row, record) => {
        return <div>{record?.answer}</div>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "10%",
      // sorter: (a, b) => a?.birthdate - b?.birthdate,
      render: (row, record) => {
        return (
          <>
            <div>{dayjs(record?.updated_at).format("DD MMM, YYYY")}</div>
            <div>{dayjs(record?.updated_at).format("HH:mm")}</div>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (row, record) => {
        return (
          <Space size={10}>
            <Button
              htmlType="submit"
              className="view-btn"
              onClick={() =>
                navigate(ROUTES.editFaq, { state: { faqData: record } })
              }
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => showModal(record)}
              htmlType="submit"
              className="view-btn"
            >
              <DeleteIcon />
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <Wrapper>
      <div className="content-wrapper">
        <div className="justify-content-between">
          <Form>
            <RenderInput
              col={{ md: 24, xs: 24 }}
              name="search"
              placeholder="Search here"
              onChange={debouncedSearchHandler}
            />
          </Form>
          <Button type="primary" onClick={() => navigate(ROUTES.addFaq)}>
            Add FAQ
          </Button>
        </div>
      </div>
      <br />
      <div className="content-wrapper">
        <div className="shadow-paper">
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
            scroll={{ x: 1200 }}
          />
        </div>
      </div>
      <ConfirmModal
        title="Are you sure to delete Faq?"
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        fetchFunction={deleteFaqFun}
      />
    </Wrapper>
  );
};

export default Faqs;
