import React, { createContext, useState } from "react";
import UserMetrics from "./UserMetrics";
import CompanyMetrics from "./CompanyMetrics";
import { Tabs } from "antd";
import DatePickerComp from "./DatePicker";
import dayjs from "dayjs";

export const DateContext = createContext();

const Dashboard = () => {
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [active, setActive] = useState(0);

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: "User Metrics",
      children: <UserMetrics />,
    },
    {
      key: "2",
      label: "Company Metrics",
      children: <CompanyMetrics />,
    },
  ];

  return (
    <DateContext.Provider value={{ startDate, endDate, active }}>
      <DatePickerComp
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        active={active}
        setActive={setActive}
      />
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        indicatorSize={(origin) => origin - 16}
      />
    </DateContext.Provider>
  );
};

export default Dashboard;
