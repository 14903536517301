import { notification, message } from "antd";

export const toastMessage = (type, content) => {
  if (type === "success") {
    message.success(typeof content === "string" ? content : "Success");
  } else if (type === "error") {
    message.error(
      typeof content === "string" ? content : "Something went wrong"
    );
  }
};

export const toast = (type, message) => {
  notification[type]({
    message,
  });
};
