import {
  API_ADD_FAQ,
  API_CMS_CONTENT,
  API_CMS_LIST,
  API_CRUD_FAQ,
  API_DELETE_FAQ,
  API_GET_CMS_CONTENT,
  API_GET_CONTACTUS,
  API_GET_FAQ_LIST,
  API_UPDATE_CONTACTUS,
  API_UPDATE_FAQ,
} from "../../constants";

export const getCmsMainList = () => ({
  type: "API",
  payload: {
    url: API_CMS_LIST,
    method: "GET",
    hideLoader: false,
  },
});

export const getCmsList = () => ({
  type: "API",
  payload: {
    url: API_GET_CMS_CONTENT,
    method: "GET",
    hideLoader: false,
  },
});

export const editCmsContent = (data) => ({
  type: "API",
  payload: {
    url: API_CMS_CONTENT,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getFaqList = (params) => ({
  type: "API",
  payload: {
    url: API_GET_FAQ_LIST + params,
    method: "GET",
    hideLoader: false,
  },
});

export const getFaq = (id) => ({
  type: "API",
  payload: {
    url: API_CRUD_FAQ + `/${id}`,
    method: "GET",
    hideLoader: false,
  },
});

export const addFaq = (data) => ({
  type: "API",
  payload: {
    url: API_ADD_FAQ,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const editFaq = (data, id) => ({
  type: "API",
  payload: {
    url: API_UPDATE_FAQ + `/${id}`,
    method: "PATCH",
    data: data,
    hideLoader: false,
  },
});

export const deleteFaq = (id) => ({
  type: "API",
  payload: {
    url: API_DELETE_FAQ + id,
    method: "DELETE",
    hideLoader: false,
  },
});

export const getContactUs = () => ({
  type: "API",
  payload: {
    url: API_GET_CONTACTUS,
    method: "GET",
    hideLoader: false,
  },
});

export const updateContactUs = (data) => ({
  type: "API",
  payload: {
    url: API_UPDATE_CONTACTUS,
    method: "PATCH",
    data: data,
    hideLoader: false,
  },
});
