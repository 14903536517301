import { createGlobalStyle } from 'styled-components';

export const Variables = createGlobalStyle`

:root{
    /*----- Theme Color -----*/
    --theme--color: #074992;
    
    --tabletext--color: #5F646D;
    --border--color: #DAE5E3;

    /*----- Common Colors -----*/
    --success--color: #089B55;
    --danger--color: #FF4D4F;
    --warning--color: #E5B64B;
    --info--color: #448FB8;
    --white--color: #ffffff;
    --black--color: #000000;
    --gray--color: #d3d3d3;
    --green--color: #34A853;
    --active-menu-bg--color: #E6F7FF;
    --active-menu--color: #1890FF;

    /*----- Font Family -----*/
    --font-primary: 'Fira Sans', sans-serif;
    --font-secondary: 'Roboto', sans-serif;
    --font-inter: 'Inter', sans-serif;
}
`;
