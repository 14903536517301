import styled from "styled-components";

export const Wrapper = styled.div`
  .chart-section {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: white;
    padding: 10px;
  }
  .user-tag-sec {
    background-color: white;
    padding: 10px;
  }
  .chart-user {
    display: flex;
  }
  .card-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .date-div {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 10px;

    span {
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
  .date-second-div {
    margin-top: 10px;
    display: flex;
    /* justify-content: center; */
    gap: 20px;
    margin-bottom: 20px;
    margin-left: 20px;

    span {
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
  .chart-axis-sec {
    margin-left: 15px;
    p {
      font-size: 14px;
    }
  }
  .list-user {
    margin-bottom: 20px;
  }

  .chart-sec {
    max-width: 700px;
    width: 100%;
  }
  .card-user {
    p {
      color: #00000073;
      margin-bottom: 20px;
    }
    span {
      color: #000000d9;
      font-weight: bold;
      font-size: 30px;
    }
    text-align: center;
    width: 300px;
  }
  .main-register {
    background-color: white;
    padding: 10px;
    width: 60%;
  }
  .register-guest-count {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .register-count {
    p {
      color: var(--geek-blue-6, #2f54eb);
      /* Body/regular */
      /* font-family: Roboto; */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    div {
      font-size: 20px;
      color: #2f54eb;
    }
  }
  .full-name {
    width: 120px;
  }
  .highest-tag {
    width: 20%;
    margin: 0 auto;
    ul {
      width: 90%;
    }
    li {
      display: flex;
      gap: 10px;
    }
    .title-high {
      margin-bottom: 20px;
    }
    div {
      width: calc(100% - 10px);
    }
  }
  .select-drop {
    float: right;
    margin: 5px 0px;
  }
  .guest-count {
    p {
      color: var(--geek-blue-6, #00000073);
      /* Body/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    div {
      font-size: 20px;
    }
  }
`;
