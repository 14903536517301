import React, { useContext, useEffect, useState } from "react";
import {
  getTagsCount,
  getUserCount,
} from "../../../Redux/DashboardSlice/action";
import { useDispatch } from "react-redux";
import { Wrapper } from "../styles";
import { Card } from "antd";
import dayjs from "dayjs";
import { DateContext } from "..";

const MetricCard = ({ title, value }) => (
  <Card className="card-user">
    <p>{title}</p>
    <span>{value}</span>
  </Card>
);

const UserTagsSection = ({ setRegisterGuestUserCount }) => {
  const dispatch = useDispatch();

  const { startDate, endDate } = useContext(DateContext);

  const [userCountData, setUserCountData] = useState({});
  const [tagsCountData, setTagsCountData] = useState({});

  useEffect(() => {
    dispatch(getUserCount({ startDate: startDate, endDate: endDate }))
      .then((res) => {
        setUserCountData(res?.data);
        setRegisterGuestUserCount({
          registerCount: res?.data?.totalUsersCntWODate,
          guestCount: res?.data?.guestUsersCnt,
        });
      })
      .catch(() => {
        setUserCountData({});
      });
    dispatch(getTagsCount({ startDate: startDate, endDate: endDate }))
      .then((res) => setTagsCountData(res?.data))
      .catch(() => {
        setTagsCountData({});
      });
  }, [dispatch, startDate, endDate]);

  const metricsData = React.useMemo(
    () => [
      { title: "Total Registered Users", value: userCountData?.totalUsers },
      { title: "Active Users", value: userCountData?.totalActiveUsers },
      { title: "Total Tags Created", value: tagsCountData?.totalTags },
      {
        title: "Avg. Registered Users Per Day",
        value: userCountData?.avgUsersPerDay,
      },
      {
        title: "Avg. Tags Created Per User",
        value: tagsCountData?.avgTagsCountPerUser || 0,
      },
    ],
    [userCountData, tagsCountData]
  );

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };

  return (
    <Wrapper>
      <div className="card-div">
        {metricsData?.map((metric) => (
          <MetricCard
            key={metric.title}
            title={metric.title}
            value={metric.value}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default UserTagsSection;
