import {
  API_CHANGE_PASSWORD,
  API_FORGOT_PASSWORD,
  API_GET_PROFILE_DETAILS,
  API_LOGIN,
  API_LOGOUT,
  API_REFRESH_TOKEN,
  API_RESET_PASSWORD,
  API_UPDATE_PROFILE,
  LOGIN_F,
  LOGIN_S,
} from "../../constants";

export const loginAction = (data) => ({
  type: "API",
  payload: {
    url: API_LOGIN,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: () => ({
      type: LOGIN_F,
      payload: {},
    }),
  },
});

export const forgotPasswordAction = (data) => ({
  type: "API",
  payload: {
    url: API_FORGOT_PASSWORD,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const resetPasswordAction = (data) => ({
  type: "API",
  payload: {
    url: API_RESET_PASSWORD,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const changePasswordAction = (id, data) => ({
  type: "API",
  payload: {
    url: API_CHANGE_PASSWORD + id,
    method: "PATCH",
    data: data,
    hideLoader: false,
  },
});

export const logoutAction = () => ({
  type: "API",
  payload: {
    url: API_LOGOUT,
    method: "POST",
    hideLoader: false,
  },
});

export const refreshTokenAction = (data) => ({
  type: "API",
  payload: {
    url: API_REFRESH_TOKEN,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getProfileDetails = (id) => ({
  type: "API",
  payload: {
    url: API_GET_PROFILE_DETAILS + id,
    method: "GET",
    hideLoader: false,
  },
});
export const updateProfileDetails = (id, data) => ({
  type: "API",
  payload: {
    url: API_UPDATE_PROFILE + id,
    method: "PATCH",
    data: data,
    hideLoader: false,
  },
});
