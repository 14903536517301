import {
  API_DELETE_TAG,
  API_GET_TAGS_LIST,
  API_STATUS_CHANGE,
} from "../../constants";

export const getTagsList = (params) => ({
  type: "API",
  payload: {
    url: API_GET_TAGS_LIST + params,
    method: "GET",
    hideLoader: false,
  },
});

export const deleteTagAction = (id) => ({
  type: "API",
  payload: {
    url: API_DELETE_TAG + id,
    method: "DELETE",
    hideLoader: false,
  },
});

export const changeTagStatus = (params) => ({
  type: "API",
  payload: {
    url: `${API_STATUS_CHANGE}/${params?.id}/${params?.status}`,
    method: "PATCH",
    hideLoader: false,
  },
});
