import { createSlice } from '@reduxjs/toolkit';
import { USER_INFO_F, USER_INFO_S, USER_LIST_F, USER_LIST_S } from '../../constants';

const initialState = {
  userList: [],
  userInfo: {}
};

// Reducer
const userManagementSlice = createSlice({
  name: 'user',
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(USER_LIST_S, (state, action) => {
      state.userList = action.payload.data;
    });
    builder.addCase(USER_LIST_F, (state) => {
      state.userList = [];
    });
    builder.addCase(USER_INFO_S, (state, action) => {
      state.userInfo = action.payload.data;
    });
    builder.addCase(USER_INFO_F, (state) => {
      state.userInfo = {};
    });
  }
});

export const { loaderChange } = userManagementSlice.actions;
export default userManagementSlice.reducer;
