import { createGlobalStyle } from "styled-components";

export const FormStyle = createGlobalStyle`
.ant-form-item {
    margin: 0;

    .ant-form-item-label {
        label {
            height: auto;
            font-family:var(--font-primary);
            font-weight: 500;
            font-size: 14px;
            color: var(--black--color);

            /* &:before {
                display: none !important;
            } */
        }
    }

    .ant-form-item-control {
        position: relative;

        textarea.ant-input {
            height: 120px;
            resize: none;
            font-family:var(--font-secondary);
            font-weight: 400;
            font-size: 14px;
            color: var(--black--color);
            background: var(--white--color);
            border: 1px solid var(--border--color);
            border-radius: 8px;
            box-shadow: none;
        }

        .ant-input,.ant-picker {
            background: var(--white--color);
            border: 1px solid var(--border--color);
            border-radius: 8px;
            font-family:var(--font-inter);
            font-weight: 400;
            font-size: 14px;
            color: var(--black--color);
            height: 45px;
            padding: 6px 16px;
            box-shadow: none;
            width:100%;

            &:focus {
                border: 1px solid var(--theme--color);
                box-shadow: none;
            }
        }

        .ant-input-affix-wrapper {
            border: none;
            box-shadow: none;
            padding: 0;
            position: relative;

            textarea.ant-input {
                padding: 6px 16px;
            }

            .ant-input {
                padding: 6px 30px 6px 16px;
            }

            .ant-input-suffix {
                position: absolute;
                right: 12px;
                top: 0;
                bottom: 0;
                svg{
                    font-size: 16px;
                    width: 16px;
                    height: 16px;
                }
                .btn-icon {
                    line-height: 10px;
                }
            }
        }

        .ant-select {
            .ant-select-selector {
                background: var(--white--color);
                border: 1px solid var(--border--color);
                border-radius: 8px;
                box-shadow: none;
                font-family:var(--font-secondary);
                font-weight: 400;
                font-size: 14px;
                color: var(--black--color);
                min-height: 45px;
                display: flex;
                align-items: center;

                .ant-select-selection-placeholder {
                    font-family:var(--font-secondary);
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--gray--color);
                }

                .ant-select-selection-search-input {
                    height: 100%;
                }

                .ant-select-selection-overflow {
                    gap: 5px;
                    .ant-select-selection-item {
                        margin: 0;
                        background: var(--white--f0f6f6);
                        border-radius: 4px;
                        padding: 6px;
                        height: auto;
                        min-height: 32px;
                        display: flex;
                        align-items: center;
                        border: none;

                        .ant-select-selection-item-content {
                            color: var(--black--color);
                            font-family:var(--font-secondary);
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                        }

                        .ant-select-selection-item-remove {
                            display: flex;
                            align-self: center;
                            justify-content: center;
                        }
                    }
                }
            }

            &:hover {
                .ant-select-selector {
                    border: 1px solid var(--theme--color) !important;
                }
            }

            &.ant-select-focused {
                .ant-select-selector {
                    border: 1px solid var(--theme--color) !important;
                    box-shadow: none !important;
                }
            }
        }

        .ant-input-group {
            border: 1px solid var(--gray--color);
            border-radius: 8px;
 
            .ant-input-group-addon {
                border: none;
                border-right: 1px solid var(--gray--color);
            }

            .ant-input {
                border: none;
            }

            .ant-select.ant-select.ant-select-single {
                min-height:32px;
                height: auto;
                .ant-select-selector {
                    padding: 0;
                    border: none !important;

                    .ant-select-selection-item {
                        padding: 10px;
                    }
                }

                .ant-select-arrow {
                    display: none;
                }
            }
        }
    }
     
    .ant-form-item-explain{
        margin-top: 5px;

        .ant-form-item-explain-error{
            font-family: var(--font-secondary);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: .5px;
            color: var(--danger--color);
        }
    }

    &.ant-form-item-has-error{
        .ant-form-item-control {
            .ant-input {
                border: 1px solid var(--danger--color);
            }
        }
    }
}

/* --- ant-select --- */
.ant-select-dropdown{
    .ant-select-item {
        background-color: var(--white--color);
        font-family: var(--font-secondary);
        font-size: 14px;
        font-weight: 400;
        border-radius: 0;
        color: var(--black--color);    
        .ant-select-item-option-state{
            svg *{
                color: var(--white--color);  
            } 
        }          
        &:hover,&.ant-select-item-option-active{
            background-color: var(--white--color);
            color: var(--black--color);
            
        }
        &.ant-select-item-option-selected{
            background-color: var(--theme--color);
            color:var(--white--color);
        }
    }
}

/* --- ant-datepicker --- */
.ant-picker-dropdown {
    .ant-picker-footer {
        display: none;
    }

    .ant-picker-date-panel {
        width: 350px;
        .ant-picker-content {
            th {
                font-family: var(--font-secondary);
                font-weight: 400;
                font-size: 12px;
                color: var(--black--color);
            }
        }
        .ant-picker-content {
            width: 100%;
        }
    }

    .ant-picker-header-view {
        button {
            &:hover {
                color: var(--theme--color);
            }
        }
    }
    .ant-picker-date-panel {
        .ant-picker-cell {
            font-family: var(--font-secondary);
            font-weight: 400;
            font-size: 16px;
            color: var(--black--color);
            padding: 3px 0;
            &:before {
                display: none;
            }
            .ant-picker-cell-inner {
                min-width: 34px;
                height: 34px;
                line-height: 34px;
                background-color: transparent;
                border-radius: 100%;
                &:before {
                    display: none;
                }
            }

            &.ant-picker-cell-in-view {
                color: var(--black--color);
            }

            &.ant-picker-cell-today,
            &.ant-picker-cell-selected {
                color: var(--white--color);
                .ant-picker-cell-inner {
                    background-color: var(--theme--color);
                    width: 34px;
                    height: 34px;
                    border-radius: 100%;
                    margin: auto;
                }
            }

            &:hover {
                color: var(--white--color);
                .ant-picker-cell-inner {
                    background-color: var(--theme--color) !important;
                }
            }
        }
    }

    .ant-picker-month-panel,
    .ant-picker-year-panel,
    .ant-picker-decade-panel {
        .ant-picker-cell {
            font-family: var(--font-secondary);
            font-weight: 400;
            font-size: 16px;
            color: var(--black--color);
            &:before {
                display: none;
            }

            &:hover {
                color: var(--white--color);
                .ant-picker-cell-inner {
                    background: var(--theme--color) !important;
                }
            }
            &.ant-picker-cell-today,
            &.ant-picker-cell-selected {
                color: var(--white--color);
                .ant-picker-cell-inner {
                    background: var(--theme--color) !important;
                }
            }
        }
    }
   
}

/* --- ant-upload --- */
.ant-upload-wrapper.ant-upload-picture-card-wrapper{
    .ant-upload-list-picture-card{
        .ant-upload-select{
            border: 1px solid var(--border--color);
           &:hover{
                border-color: var(--theme--color) !important;
           }                
        }
    }
}


/* --- Checkbox --- */
.ant-checkbox-wrapper{
    .ant-checkbox{
        .ant-checkbox-inner{
            border: 1px solid var(--border--color);
        }
        &.ant-checkbox-checked{
            .ant-checkbox-inner{
                background-color: var(--theme--color) !important;
                border:1px solid var(--theme--color) !important;
            }
        }
        &:after{
            display: none;
        }
    }
    &:hover{
        .ant-checkbox{
            .ant-checkbox-inner{
                border-color: var(--theme--color);
            }
        }
    }
}

/* --- Radio --- */
.ant-radio-wrapper{
    .ant-radio{
        .ant-radio-inner{
            border-color: var(--border--color);
        }
        &.ant-radio-checked{
            .ant-radio-inner{
                background-color: var(--theme--color);
                border-color: var(--theme--color);
            }
        }
        &:after{
            display: none;
        }
    }
}


`;
