import styled from "styled-components";

export const Wrapper = styled.div`
  .justify-content-between {
    display: flex;
    justify-content: space-between;
  }
  .faq-btn-section {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
  }
  .quill {
    width: 500px;
    height: fit-content;
  }
  .view-profile-btn-section {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
  }
  .text-contact {
    color: rgba(0, 0, 0, 0.85);

    /* H5/medium */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
`;
