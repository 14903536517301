import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    if (!isLoggedIn) navigate(ROUTES.login, { replace: true });
  }, [isLoggedIn, navigate]);

  return <>{children}</>;
};

export default AuthGuard;
