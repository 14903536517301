import React, { useEffect, useState } from "react";
import { Wrapper } from "./Style";
import { Button, Form, Space, Table } from "antd";
import { RenderInput } from "../../components/common/FormField";
import { useDispatch } from "react-redux";
import { getCmsMainList } from "../../Redux/ContentManagementSlice/action";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const ContentManagement = () => {
  const [cmsdata, setCmsData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCmsMainList())
      .then((res) => {
        setCmsData(res?.data);
        setDataSource(res?.data);
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  const handleSearch = (e) => {
    const currValue = e.target.value;
    setSearchValue(currValue);
    const filteredData = cmsdata.filter((item) =>
      item.title.toLowerCase().includes(currValue.toLowerCase())
    );
    setDataSource(filteredData);
  };

  const handleClick = (title) => {
    if (title === "FAQ's") {
      navigate(ROUTES.faqs);
    } else if (title === "Terms & Conditions") {
      navigate(ROUTES.termsAndCondition);
    } else if (title === "Privacy Policy") {
      navigate(ROUTES.privacyPolicy);
    } else {
      navigate(ROUTES.contentSection);
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      //   sorter: (a, b) => a?.name - b?.name,
      render: (row, record) => {
        return <div>{record?.title}</div>;
      },
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "20%",
      // sorter: (a, b) => a?.birthdate - b?.birthdate,
      render: (row, record) => {
        return (
          <>
            <div>{dayjs(record?.updated_at).format("DD MMM, YY")}</div>
            <div>{dayjs(record?.updated_at).format("HH:mm")}</div>
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "20%",
      render: (row, record) => {
        return (
          <Space size={10}>
            <Button
              htmlType="submit"
              className="view-btn"
              onClick={() => handleClick(record?.title)}
            >
              {record?.title === "Terms & Conditions" ||
              record?.title === "Privacy Policy"
                ? "Edit"
                : "View"}
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <Wrapper>
      <div className="content-wrapper">
        <Form>
          <RenderInput
            col={{ md: 8, xs: 24 }}
            name="search"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
          />
        </Form>
      </div>
      <br />
      <div className="content-wrapper height-min">
        <div className="shadow-paper">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey={(record) => record.title}
            scroll={{ x: 400 }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ContentManagement;
