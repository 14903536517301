import { createGlobalStyle } from 'styled-components';
import { device } from './Common/breakpoints';

export const ThemeStyle = createGlobalStyle` 
.ant-menu-submenu.ant-menu-submenu-popup {
    background: var(--theme--color);
    max-width: 250px;
    @media screen and (${device?.sm}){
        max-width: 150px;
    }
    .ant-menu {
        background-color: var(--theme--color) !important;
        .ant-menu-item {
            display: inline-flex;
            align-items: center;
            height: 100% !important;
            min-height: 40px;
            color: var(--white--color);
            line-height: 1.6;
            &:not(:last-child){
                margin-bottom: 0;
            }
            &.ant-menu-item-active,&.ant-menu-item-selected {
                background-color: var(--white--color) !important;
                color: var(--black--color);
            }
        }
        .ant-menu-title-content {
            font-family: var(--font-secondary);
            font-size: 14px;
        }
    }
}
`;
