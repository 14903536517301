import { Checkbox, Col, Form, Input, InputNumber, Radio, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
// import "flagpack/dist/flagpack.css";

export const RenderInput = (props) => {
  return (
    <Col {...props.col}>
      <Form.Item
        name={props.name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={props.label}
        rules={props.rules}
        required={props.required}
        help={
          props.isError ? (
            <p className="slot-error">{props.errorMessage}</p>
          ) : null
        }
      >
        <Input
          readOnly={props.readOnly}
          value={props.value}
          disabled={props.disabled}
          addonBefore={props.addonBefore}
          addonAfter={props.addonAfter}
          suffix={
            props.icon && <span className="suffix-icon">{props.icon}</span>
          }
          placeholder={props.placeholder}
          type={props.type}
          className={props.className}
          onChange={props.onChange}
          onWheel={(e) => e.target.blur()}
          allowClear={props.allowClear}
        />
      </Form.Item>
    </Col>
  );
};

export const RenderPhoneNumber = (props) => {
  return (
    <Col {...props.col}>
      <ConfigProvider
        locale={en}
        areaMapper={(area) => {
          return {
            ...area,
            emoji: (
              <span
                style={{ verticalAlign: "middle" }}
                className={`fp ${area.short.toLowerCase()}`}
              />
            ),
          };
        }}
      >
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
          required={props.required}
          initialValue={props.initialValue}
          help={
            props.isError ? (
              <p className="slot-error">{props.errorMessage}</p>
            ) : null
          }
        >
          <CountryPhoneInput
            inline
            type={props.type}
            maxLength={props.maxLength}
            onInput={
              props.onInput
                ? (e) => {
                    if (e?.target?.value?.length > 10) {
                      e.target.value = e.target.value.slice(0, 10); // Truncate to 10 characters
                    }
                  }
                : undefined
            }
            className={props.className}
            value={props.value}
            readOnly={props.readOnly}
            disabled={props.disabled}
            placeholder={props.placeholder}
            onChange={props.onChange}
          />
        </Form.Item>
      </ConfigProvider>
    </Col>
  );
};

export const RenderPassword = (props) => {
  return (
    <Col {...props.col}>
      <Form.Item
        name={props.name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={props.label}
        rules={props.rules}
        className="common-form-field"
      >
        <Input.Password placeholder={props.placeholder} />
      </Form.Item>
    </Col>
  );
};

export const RenderNumberInput = (props) => {
  return (
    <Col {...props.col}>
      <Form.Item
        name={props.name}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        label={props.label}
        rules={props.rules}
        className="common-form-field"
      >
        <InputNumber placeholder={props.placeholder} />
      </Form.Item>
    </Col>
  );
};

export const RenderTextArea = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={props.label}
          rules={props.rules}
          required={props.required}
        >
          <TextArea
            disabled={props.disabled}
            placeholder={props.placeholder}
            readOnly={props.readOnly}
            autoSize={props.autoSize}
            rows={props.rows}
            value={props.value}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderSelect = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={props.className}
          rules={props.rules}
          noStyle={props.noStyle}
        >
          <Select
            showSearch
            onChange={props.onChange}
            // suffixIcon={<BottomIcon />}
            style={props.style}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onSearch={props.onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          >
            {props?.optionLabel?.map((item) => (
              <Select.Option
                key={item.value}
                value={item.value}
                label={item.name}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderMultiSelect = (props) => {
  return (
    <>
      <Col {...props.col}>
        <Form.Item
          name={props.name}
          label={props.label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className={props.className}
          rules={props.rules}
        >
          <Select
            mode="multiple"
            showArrow={true}
            value={props.value}
            placeholder={props.placeholder}
            defaultValue={props.defaultSelectValue}
            // onChange={handleChange}
            optionLabelProp={
              props.selectOptionLabelProp === true ? "label" : ""
            }
            // suffixIcon={<BottomIcon />}
            className={props.readOnly ? "readOnly" : undefined}
          >
            {props?.optionLabel?.map((item) => (
              <Select.Option
                key={item.value}
                value={item.value}
                label={item.name}
              >
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

export const RenderCheckbox = (props) => {
  return (
    <Col {...props.col}>
      <Form.Item
        name={props.name}
        label={props.label}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        {/* <Checkbox>{props.formLabel}</Checkbox> */}
        <Checkbox.Group options={props.options} onChange={props.onChange} />
      </Form.Item>
    </Col>
  );
};

export const RenderRadioGroup = (props) => {
  return (
    <Col {...props.col}>
      <Form.Item
        name={props.name}
        label={props.label}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={props.rules}
      >
        <Radio.Group
          options={props.options}
          onChange={props.onChange}
          value={props.value}
          className={props.className}
        />
      </Form.Item>
    </Col>
  );
};
