import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from './Style';

const Loading = ({ isSuspense, children }) => {
  const isLoading = useSelector((state) => state?.auth.isLoading);

  return (
    <Loader>
      {(isSuspense || isLoading) && (
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="bounce"></div>
            <div className="double-bounce"></div>
          </div>
        </div>
      )}
      {children}
    </Loader>
  );
};

export default Loading;
