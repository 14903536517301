export const validatePassword = (_, value) => {
  const regex = /^(?!.*\s).{8,16}$/;
  if (value && !regex.test(value)) {
    return Promise.reject(
      new Error('Password  must be 8-16 characters long and not contain white spaces')
    );
  }

  return Promise.resolve();
};

export const validatePhoneNumber = (_, value) => {
  const regex = /^[0-9]+$/;
  if (value && !value?.phone) {
    return Promise.reject(new Error('Please enter phone number'));
  } else if (value && !regex.test(value.phone)) {
    return Promise.reject(new Error('Only digit allow'));
  } else if (value && value?.phone.length !== 10) {
    return Promise.reject(new Error('Mobile Number should be 10 digit'));
  }

  return Promise.resolve();
};

export const validateNumber = (_, value) => {
  const regex = /^[0-9.]+$/;
  if (value && !regex.test(value)) {
    return Promise.reject(new Error('Only digit allow'));
  }

  return Promise.resolve();
};

export const validateWhiteSpace = (_, value) => {
  const regex = /^\s+$/;
  if (value && regex.test(value)) {
    return Promise.reject(new Error('Please enter valid text'));
  }

  return Promise.resolve();
};
