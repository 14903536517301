// ENV CONSTANTS
export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_NAME: APP_NAME } = process.env;

// LOCALSTORAGE KEYNAME
export const LS_USER = `user${APP_NAME}`;
export const LS_AUTHTOKEN = `authToken${APP_NAME}`;

// TYPES FOR REDUCER
export const LOGIN_S = `LOGIN_S`;
export const LOGIN_F = `LOGIN_F`;

export const USER_LIST_S = `USER_LIST_S`;
export const USER_LIST_F = `USER_LIST_F`;

export const USER_INFO_S = `USER_INFO_S`;
export const USER_INFO_F = `USER_INFO_F`;

// API ENDPOINTS
export const API_LOGIN = `auth/login`;
export const API_LOGOUT = `auth/logout`;
export const API_REFRESH_TOKEN = `auth/refresh-token`;
export const API_FORGOT_PASSWORD = `auth/forgotPassword`;
export const API_RESET_PASSWORD = `auth/resetPassword`;
export const API_CHANGE_PASSWORD = `admin/change-password/`;
export const API_PROFILE = `admin/profile`;

//USER MANAGEMENT
export const API_GET_USER_LIST = `admin/userManagement/list`;
export const API_CHANGE_STATUS = `admin/userManagement`;
export const API_GET_FOLLOW_UNFOLLOW = `users/follow-unfollow`;
export const API_GET_AVG_RATING = `users/average-rating`;
export const API_GET_ALL_RATING = `users/get-all-ratings`;

export const API_USER_LIST = `admin/userList`;
export const API_USER_INFO = `admin/userInfo`;

export const API_DELETE_USER = `admin/userdelete/`;

//FEEDBACK MANAGEMENT
export const API_GET_FEEDBACK_LIST = `admin/feedback/listFeedbacks`;
export const API_DELETE_FEEDBACK = `admin/feedback/removeFeedback/`;

//API LIMIT
export const UPDATE_API_LIMIT = `apiLimit/updateLimit`;
export const GET_API_LIMIT = `apiLimit`;

//MAP TYPE
export const UPDATE_MAP_TYPE = `maptype/updatemaptype`;
export const GET_MAP_TYPE = `maptype`;

//PROFILE MANAGEMENT

export const API_GET_PROFILE_DETAILS = `admin/getAdminProfile/`;
export const API_UPDATE_PROFILE = `admin/updateAdminProfile/`;

//TAGS MANAGEMENT
export const API_GET_TAGS_LIST = `admin/tag/listTags`;
export const API_DELETE_TAG = `web/tag/removeTag/`;
export const API_STATUS_CHANGE = `admin/tag`;

//CONTENT MANAGEMENT
export const API_CMS_CONTENT = `admin/cms`;
export const API_GET_CMS_CONTENT = `admin/cms/cmsList`;

export const API_CMS_LIST = `cms/content-section`;
export const API_GET_FAQ_LIST = `admin/faq/listFaqs`;
export const API_CRUD_FAQ = `cms/faq`;
export const API_ADD_FAQ = `admin/addFaq`;
export const API_UPDATE_FAQ = `admin/updateFaq`;
export const API_DELETE_FAQ = `admin/removeFaq/`;
export const API_UPDATE_CONTACTUS = `web/contactus/updateContactUs`;
export const API_GET_CONTACTUS = `web/contactus`;

//DASHBOARD MANAGEMENT
export const API_USER_COUNT = "admin/userCount";
export const API_TAGS_COUNT = "admin/tagsCount";
export const API_COMPANY_METRIX_COUNT = "admin/companyMetricsCntS1";
export const API_COMPANY_METRIX_CHART = "admin/companyMetricsUserCntS2";
export const API_COMPANY_METRIX_REGISTER = "admin/companyMetricsUserCntS3";
export const API_GET_ERRORS_LIST = "erroredapis/urlList";
export const SESSION_TAG_DETAILS = "admin/sessionandtagdetails";

export const API_DATE_WISE_COUNT = "admin/dateWiseTagsCreatedCnt";

// MASTER ARRAY
export const moduleMaster = [
  { id: 1, name: "User Management", enabled: false },
  { id: 2, name: "Product Management", enabled: false },
  { id: 3, name: "Order Management", enabled: false },
  { id: 4, name: "Issue Management", enabled: false },
  { id: 5, name: "Sub-Admin Management", enabled: false },
  { id: 6, name: "Subscription Management", enabled: false },
  { id: 7, name: "CMS Management", enabled: false },
];

export const MESSAGE_STATUS = {
  SENT: "sent",
  DELIVERED: "delivered",
  READ: "read",
};

export const GENDER_OPTIONS = [
  { _id: "1", name: "Male" },
  { _id: "2", name: "Female" },
  { _id: "3", name: "Non binary" },
  { _id: "4", name: "Prefer not to say" },
];

export const API_LIMIT_PARAM = {
  PLACE_API: "place_api",
  MAP_API: "map_api",
};

export const USER_TYPE = {
  1: "guest",
  2: "registered",
  3: "all",
};
