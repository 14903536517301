import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Wrapper } from "../styles";

const DatePickerComp = (props) => {
  const { setStartDate, setEndDate, active, setActive } = props;
  const { RangePicker } = DatePicker;

  const [rangePickerValue, setRangePickerValue] = useState(null);

  const handleDateClick = (startModifier, endModifier) => {
    setStartDate(dayjs().startOf(startModifier).format("YYYY-MM-DD"));
    setEndDate(dayjs().endOf(endModifier).format("YYYY-MM-DD"));
    setRangePickerValue(null);
  };

  const handleDateChange = (dates) => {
    setActive();
    setRangePickerValue(dates);
    if (dates) {
      const [start, end] = dates;
      setStartDate(dayjs(start).format("YYYY-MM-DD"));
      setEndDate(dayjs(end).format("YYYY-MM-DD"));
    } else {
      // Clear dates if the range is reset
      setStartDate(null);
      setEndDate(null);
      setActive(0);
      setStartDate(dayjs().format("YYYY-MM-DD"));
      setEndDate(dayjs().format("YYYY-MM-DD"));
    }
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };

  return (
    <Wrapper>
      <div className="date-div">
        <span
          className={active === 0 && "active"}
          onClick={() => {
            setActive(0);
            handleDateClick("day", "day");
          }}
        >
          Day
        </span>
        <span
          className={active === 1 && "active"}
          onClick={() => {
            setActive(1);
            handleDateClick("week", "week");
          }}
        >
          Week
        </span>
        <span
          className={active === 2 && "active"}
          onClick={() => {
            setActive(2);
            handleDateClick("month", "month");
          }}
        >
          Month
        </span>
        <span
          className={active === 4 && "active"}
          onClick={() => {
            setActive(4);
            setStartDate(dayjs("2023-09-01").format("YYYY-MM-DD"));
            setEndDate(dayjs().format("YYYY-MM-DD"));
            setRangePickerValue(null);
          }}
        >
          Always
        </span>
        <span>
          <RangePicker
            value={rangePickerValue}
            onChange={handleDateChange}
            disabledDate={disabledDate}
          />
        </span>
      </div>
    </Wrapper>
  );
};

export default DatePickerComp;
