import { Button, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { Wrapper } from "./errorStyles";
import { useDispatch } from "react-redux";
import { usePaginationTable } from "../../components/common/Table";
import { getErrorsList } from "../../Redux/DashboardSlice/action";

const ErrorPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchData = async (requestArgs) => {
    const { current, pageSize, sortBy, sortOrder, search } = requestArgs;

    let params = `?search=${search || ""}&page=${current || 1}&size=${
      pageSize || 10
    }&sort_by=${sortBy ? `${sortBy}` : ""}&sort_order=${sortOrder || ""}`;

    const response = await dispatch(getErrorsList(params)).catch((err) =>
      console.error(err)
    );
    return { data: response?.data?.list, total: response?.data?.total_count };
  };

  const { handleTableChange, data, loading, pagination } = usePaginationTable(
    fetchData,
    ""
  );

  const columns = [
    {
      title: "URLs",
      dataIndex: "api_url",
      key: "api_url",
    },
  ];

  return (
    <Wrapper>
      <div className="content-wrapper">
        <div className="view-user-btn-section">
          <Button onClick={() => navigate(-1)}>Back</Button>
        </div>
      </div>
      <div className="content-wrapper  mt-16">
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={pagination}
          onChange={handleTableChange}
          rowKey={(record) => record.id}
          scroll={{ x: 400 }}
        />
      </div>
    </Wrapper>
  );
};

export default ErrorPage;
