import { API_DELETE_FEEDBACK, API_GET_FEEDBACK_LIST } from "../../constants";

export const getFeedbackList = (params) => ({
  type: "API",
  payload: {
    url: API_GET_FEEDBACK_LIST + params,
    method: "GET",
    hideLoader: false,
  },
});

export const deleteFeedbackAction = (params) => ({
  type: "API",
  payload: {
    url: API_DELETE_FEEDBACK + params,
    method: "DELETE",
    hideLoader: false,
  },
});
