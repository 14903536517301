import React, { useState } from "react";
import { RenderInput } from "../../components/common/FormField";
import { useNavigate } from "react-router";
import { Button, Form, Space, Table } from "antd";
import { ROUTES } from "../../constants/routes";
import { useDispatch } from "react-redux";
import { usePaginationTable } from "../../components/common/Table";
import { debounce } from "../../utils/utilFunctions";
import { DeleteIcon, ViewIcon } from "../../svg";
import { Wrapper } from "./Style";
import {
  deleteFeedbackAction,
  getFeedbackList,
} from "../../Redux/FeedbackSlice/action";
import dayjs from "dayjs";
import ConfirmModal from "../../components/common/Modal/ConfirmModal";
import { toastMessage } from "../../components/common/ToastMessage";

const FeedbackManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const fetchData = async (requestArgs) => {
    const { current, pageSize, sortBy, sortOrder, search } = requestArgs;

    let params = `?search=${search || ""}&page=${current || 1}&size=${
      pageSize || 10
    }&sort_by=${sortBy ? `${sortBy}` : ""}&sort_order=${sortOrder || ""}`;

    const response = await dispatch(getFeedbackList(params)).catch((err) =>
      console.error(err)
    );
    return { data: response?.data?.list, total: response?.data?.total_count };
  };

  const { handleTableChange, data, loading, pagination, refetch } =
    usePaginationTable(fetchData, searchInput);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const debouncedSearchHandler = debounce(handleSearchChange, 300);

  const onDeleteClick = (record) => {
    setIsModalOpen(true);
    setDeleteId(record?.id);
  };

  const deleteFeedback = () => {
    dispatch(deleteFeedbackAction(deleteId))
      .then(() => {
        refetch();
        toastMessage("success", "Feedback deleted successfully");
      })
      .catch((err) => toastMessage("error", err?.message));
  };

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNumber",
      render: (_text, _record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sortKey: "first_name",
      sorter: (a, b) => a?.first_name - b?.first_name,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sortKey: "last_name",
      sorter: (a, b) => a?.last_name - b?.last_name,
    },
    {
      title: "Reason for Inquiry",
      dataIndex: "inquiry_reason",
      key: "inquiry_reason",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      // sorter: (a, b) => a?.phone_number - b?.phone_number,
      render: (row, record) => (
        <div>
          <div>
            {record?.country_code && record?.phone_number
              ? record?.country_code + "-" + record?.phone_number
              : "-"}
          </div>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Received On",
      dataIndex: "updated_at",
      key: "updated_at",
      sortKey: "updated_at",
      sorter: (a, b) => a?.updated_at - b?.updated_at,
      render: (row, record) => (
        <div>
          {record.updated_at
            ? dayjs(record.updated_at).format("MM-DD-YYYY")
            : "-"}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (row, record) => {
        return (
          <Space size={10}>
            <Button
              htmlType="button"
              className="view-btn"
              onClick={() =>
                navigate(ROUTES.viewFeedback, {
                  state: { feedbackData: record },
                })
              }
            >
              <ViewIcon />
            </Button>
            <Button
              onClick={() => onDeleteClick(record)}
              htmlType="button"
              className="view-btn"
            >
              <DeleteIcon />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <div className="content-wrapper">
        <Form>
          <RenderInput
            col={{ md: 8, xs: 24 }}
            name="search"
            placeholder="Search Feedback"
            onChange={debouncedSearchHandler}
          />
        </Form>
      </div>{" "}
      <br />
      <div className="content-wrapper">
        <div className="shadow-paper">
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
            scroll={{ x: 400 }}
          />
        </div>
      </div>
      {isModalOpen && (
        <ConfirmModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          title={`Are you sure you want to delete this feedback ?`}
          fetchFunction={deleteFeedback}
        ></ConfirmModal>
      )}
    </Wrapper>
  );
};

export default FeedbackManagement;
