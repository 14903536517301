import { DatePicker } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCompanyMetrixRegister } from "../../../Redux/DashboardSlice/action";
import dayjs from "dayjs";
import { DateContext } from "..";

const RegisteredSection = () => {
  const dispatch = useDispatch();

  const { startDate, endDate } = useContext(DateContext);
  const [searchTagData, setSearchTagData] = useState({});

  useEffect(() => {
    dispatch(
      getCompanyMetrixRegister({ startDate: startDate, endDate: endDate })
    )
      .then((res) => {
        setSearchTagData(res?.data);
      })
      .catch(() => {
        setSearchTagData({});
      });
  }, [dispatch, startDate, endDate]);

  return (
    <div className="company-main-register">
      <p className="title">Searches</p>
      <div className="company-register-guest-count">
        <div className="company-register-count">
          <div>Total Label Searched</div>
          <div className="company-count">
            {searchTagData?.labelsSearchedCount || 0}
          </div>
        </div>
        <div className="company-guest-count">
          <div>Total Tag Searched</div>
          <div className="company-count">
            {searchTagData?.tagsSearchedCount || 0}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisteredSection;
