export const debounce = (callback, delay) => {
  let timeoutHandler = null;
  return (...args) => {
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }
    timeoutHandler = setTimeout(() => {
      callback(...args);
      timeoutHandler = null;
    }, delay);
  };
};

export const scrollToBottomFun = (wrapperId) => {
  let chatWrapper = document.getElementById(wrapperId);
  if (chatWrapper) {
    chatWrapper.scrollTo(0, chatWrapper.scrollHeight);
  }
};
