import styled from "styled-components";

export const Wrapper = styled.div`
  .company-right {
    width: 30%;
    margin-left: auto;
  }
  .company-chart-section {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: white;
    padding: 10px;
  }
  .company-user-tag-sec {
    background-color: white;
    padding: 10px;
  }
  .company-chart-user {
    display: flex;
  }
  .company-card-div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
  }
  .company-date-div {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    margin-top: 10px;

    span {
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
  .company-date-second-div {
    margin-top: 10px;
    display: flex;
    justify-content: end;
    gap: 20px;
    margin-bottom: 20px;
    margin-right: 20px;

    span {
      cursor: pointer;
    }
    .active {
      color: #1890ff;
    }
  }
  .company-chart-axis-sec {
    margin-left: 15px;
    p {
      font-size: 14px;
    }
  }
  .company-list-user {
    margin-bottom: 7px;
  }

  .company-chart-sec {
    width: 100%;
    max-width: 700px;
  }
  .company-card-user {
    .icon-tool {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      margin: 5px 10px 0 0;
    }
    p {
      color: #00000073;
      margin-bottom: 20px;
    }
    .metric-val {
      color: #000000d9;
      font-weight: bold;
      font-size: 30px;
    }
    text-align: center;
    width: 220px;
  }
  .company-main-register {
    background-color: white;
    padding: 10px;
    width: 60%;
  }
  .company-main-register .title {
    margin-bottom: 30px;
  }
  .company-register-guest-count {
    display: flex;
    justify-content: space-between;
  }
  .company-register-count {
    p {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
      /* H5/medium */
      /* font-family: Roboto; */
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      margin-bottom: 48px;
    }
    div {
      color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
      /* Body/regular */
      /* font-family: Roboto; */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    .company-count {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* color: #2f54eb; */
    }
  }
  .company-full-name {
    width: 120px;
    margin-left: 10px;
  }
  .company-highest-tag {
    /* width: 23%;
    margin: 0 auto; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    ul {
      /* width: 60%; */
    }
    li {
      display: flex;
      /* justify-content: space-between; */
    }
    h2 {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
      /* font-family: Roboto; */
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */
      margin-bottom: 10px;
    }
  }
  .company-select-drop {
    float: right;
    margin: 5px 0px;
  }
  .company-guest-count {
    p {
      color: var(--geek-blue-6, #00000073);
      /* Body/regular */
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }

    div {
      color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
      /* Body/regular */
      /* font-family: Roboto; */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px; /* 157.143% */
    }
    .company-count {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      /* color: #2f54eb; */
    }
  }
  .tabs-section {
    width: 50%;
  }
  .chart-header {
    display: flex;
    justify-content: space-between;
  }
  .pieChart-sec {
    width: 160px;
    margin: 0 auto;
    h2 {
      color: var(--character-primary-85, rgba(0, 0, 0, 0.85));
      /* font-family: Roboto; */
      font-size: 14px;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */
      margin-bottom: 10px;
    }
  }
`;
