import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { Badge, DatePicker, Tabs } from "antd";
import { Wrapper } from "../stylesCompany";
import { getCompanyMetrixChart } from "../../../Redux/DashboardSlice/action";
import { USER_TYPE } from "../../../constants";
import { DateContext } from "..";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);

export default function ChartSection() {
  const dispatch = useDispatch();
  const { startDate, endDate, active } = useContext(DateContext);
  console.log("active: ", active);

  const [dateWiseTag, setDateWiseTag] = useState({});
  const [labels, setLabels] = useState([]);
  const [geoLocationData, setGeoLocationData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [appTypeData, setAppTypeData] = useState([]);

  const [labelKey, setLabelKey] = useState("1");

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const pieData = {
    labels: ["Mobile", "Desktop"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          appTypeData
            ?.find((val) => val?.app_type === "mobile")
            ?.avg_percentage?.toString()
            ?.includes(".")
            ? Number(
                appTypeData?.find((val) => val?.app_type === "mobile")
                  ?.avg_percentage
              )?.toFixed(2)
            : Number(
                appTypeData?.find((val) => val?.app_type === "mobile")
                  ?.avg_percentage
              ),
          appTypeData
            ?.find((val) => val?.app_type === "desktop")
            ?.avg_percentage?.toString()
            ?.includes(".")
            ? Number(
                appTypeData?.find((val) => val?.app_type === "desktop")
                  ?.avg_percentage
              )?.toFixed(2)
            : Number(
                appTypeData?.find((val) => val?.app_type === "desktop")
                  ?.avg_percentage
              ),
        ],
        backgroundColor: ["#00008B", "#ffcc00"],
        borderColor: ["rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const data = {
    labels,
    datasets: [
      {
        data: dateWiseTag?.length
          ? dateWiseTag?.map((val, i) => val?.visitors_count)
          : [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  useEffect(() => {
    dispatch(
      getCompanyMetrixChart({
        startDate: startDate,
        endDate: endDate,
        user_type: USER_TYPE[Number(labelKey)],
      })
    )
      .then((res) => {
        const responseData = res?.data?.graphUserDateWiseCnt || [];
        setGeoLocationData(res?.data?.geoLocationTopUserCnt);
        setCityData(res?.data?.cityTopUserCnt);
        setAppTypeData(res?.data?.avgUsersByApptype);

        let generatedLabels;
        if (active === null || active === undefined || active === 4) {
          generatedLabels = responseData?.length
            ? responseData?.map((val) =>
                dayjs(val?.visitors_date).format("DD MMM")
              )
            : [];
          setDateWiseTag(responseData);
        } else if (responseData?.length === 1) {
          generatedLabels = [dayjs().format("DD")];
          setDateWiseTag(responseData);
        } else if (responseData?.length === 7) {
          generatedLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          setDateWiseTag(responseData);
        } else {
          const conditionalDate = dayjs(startDate).endOf("month").format("DD");
          generatedLabels = [
            "01-05",
            "06-10",
            "11-15",
            "16-20",
            "21-25",
            `26-${conditionalDate}`,
          ];
          if (responseData?.length) {
            const groupedByFive = [];
            let temp = 0;
            let temp2 = false;

            responseData.forEach((element, ind) => {
              if (ind === 30) temp2 = true;
              if (ind !== 0 && ind % 5 === 0) {
                groupedByFive.push({ visitors_count: temp });
                temp = 0;
              }
              temp += element.visitors_count;
            });
            if (temp2 === false) groupedByFive.push({ visitors_count: temp });

            setDateWiseTag(groupedByFive);
          }
        }
        setLabels(generatedLabels);
      })
      .catch(() => {
        setDateWiseTag({});
        setLabels([]);
      });
  }, [dispatch, startDate, endDate, labelKey, active]);

  const onChange = (key) => {
    setLabelKey(key);
  };

  const items = [
    {
      key: "1",
      label: "Guest Users",
    },
    {
      key: "2",
      label: "Registered Users",
    },
    {
      key: "3",
      label: "Total",
    },
  ];

  return (
    <Wrapper>
      <div className="chart-header">
        <div className="tabs-section">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>

      <div style={{ marginBottom: "10px" }}>Total Number of Users Visited</div>
      <div className="company-chart-user">
        <div className="company-chart-sec">
          <Bar options={options} data={data} />
        </div>
        <div className="company-chart-axis-sec">
          <div>X axis - Date</div>
          <div>Y axis - Total Number of Users Visited</div>
        </div>
        <div className="company-right">
          <div className="company-highest-tag">
            <div>
              <h2>GeoLocation Metrics</h2>
              <ul>
                {geoLocationData?.length
                  ? geoLocationData?.slice(0, 7)?.map((val, i) => {
                      return (
                        <li className="company-list-user">
                          <Badge count={i + 1} />
                          <span className="company-full-name">
                            {val?.geolocation}
                          </span>
                          <span>{val?.count}</span>
                        </li>
                      );
                    })
                  : "No Data Available"}
              </ul>
            </div>
            <div>
              <h2>Popular Locations</h2>
              <ul>
                {cityData?.length
                  ? cityData?.slice(0, 7)?.map((val, i) => {
                      return (
                        <li className="company-list-user">
                          <Badge count={i + 1} />
                          <span className="company-full-name">{val?.city}</span>
                          <span>{val?.count}</span>
                        </li>
                      );
                    })
                  : "No Data Available"}
              </ul>
            </div>
          </div>
          <div className="pieChart-sec">
            <h2>Mobile Metrics</h2>
            <Pie data={pieData} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
