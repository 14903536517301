import { createGlobalStyle } from 'styled-components';
import { device } from '../Common/breakpoints';

export const ModalStyle = createGlobalStyle` .ant-modal-root {
    .ant-modal-mask {
        /* background-color: var(--theme--color);
        opacity: 0.2; */
        background-color: rgba(0,0,0,.7);
    }

    .ant-modal-wrap {

        .ant-modal.common-modal {
            .ant-modal-content {
                max-height: 705px;
                overflow-y: auto;

                @media only screen and (${device?.xs}) {
                    padding: 16px;
                }

                .ant-modal-header {
                    margin-bottom: 15px;

                    .ant-modal-title {
                        font-size: 22px;
                        font-family: var(--font-primary);
                        text-align: center;

                        @media only screen and (${device?.xs}) {
                            font-size: 18px;
                        }
                    }
                }

                .ant-modal-body {
                    .modal-text {
                        font-size: 18px;
                        font-family: var(--font-secondary);
                        line-height: 22px;
                        text-align: center;

                        @media only screen and (${device?.xs}) {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }

                .ant-modal-footer {
                    margin-top: 15px;
                }
            }
            .ant-modal-content::-webkit-scrollbar {
                display: none;
            }
            
        }
    }
}

`;
