import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  .heading {
    height: 100vh;
    width: 100%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#45484d+0,000000+100;Black+3D+%231 */
    background: #45484d;
    /* Old browsers */
    background: -moz-linear-gradient(top, #45484d 0%, #000000 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #45484d 0%, #000000 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #45484d 0%, #000000 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#45484d', endColorstr='#000000', GradientType=0);
    /* IE6-9 */
  }

  .content {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  .glow-on-hover {
    margin-top: 20px;
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: rgb(147, 141, 141);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;

    &:active {
      color: #000;

      &::after {
        background: transparent;
      }
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: '';
      background: linear-gradient(
        45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000
      );
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
    }

    &:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #111;
      left: 0;
      top: 0;
      border-radius: 10px;
    }
  }

  @keyframes glowing {
    0% {
      background-position: 0 0;
    }

    50% {
      background-position: 400% 0;
    }

    100% {
      background-position: 0 0;
    }
  }
`;
