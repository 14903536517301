import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Button, Form, Row } from "antd";
import { RenderInput, RenderTextArea } from "../../components/common/FormField";
import { useDispatch } from "react-redux";
import { getApiLimit, updateApiLimit } from "../../Redux/ApiLimitSlice/action";
import { toastMessage } from "../../components/common/ToastMessage";

const ApiLimit = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [callAfterApiSuccess, setCallAfterApiSuccess] = useState(false);

  useEffect(() => {
    dispatch(getApiLimit())
      .then((res) => {
        form.setFieldsValue({
          placeApi:
            res?.data
              ?.find((val) => val?.api_name === "place_api")
              ?.apilimit_limit?.toString() || "",
          placeCall:
            res?.data
              ?.find((val) => val?.api_name === "place_api")
              ?.calls?.toString() || "",
          mapApi:
            res?.data
              ?.find((val) => val?.api_name === "map_api")
              ?.apilimit_limit?.toString() || "",
          mapCall:
            res?.data
              ?.find((val) => val?.api_name === "map_api")
              ?.calls?.toString() || "",
        });
      })
      .catch((err) => console.log("err", err));
  }, [dispatch, callAfterApiSuccess, form]);

  const handleSubmit = (values) => {
    dispatch(
      updateApiLimit({
        map_api_limit: Number(values?.mapApi),
        place_api_limit: Number(values?.placeApi),
      })
    )
      .then((res) => {
        toastMessage("success", res?.message);
        setCallAfterApiSuccess((prev) => !prev);
      })
      .catch((err) => {
        toastMessage("error", err?.message);
      });
  };

  return (
    <Wrapper>
      <div className="content-wrapper height-min">
        <Form onFinish={handleSubmit} form={form}>
          <div className="faq-btn-section">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
          <br />
          <Row gutter={[20, 20]}>
            <RenderTextArea
              col={{ md: 12, xs: 24 }}
              name="placeApi"
              label="Google Place Api Limit"
              placeholder="Enter Limit"
              autoSize={true}
              rules={[
                {
                  required: true,
                  message: "Please enter numbers only",
                  pattern: /^\d*$/,
                },
              ]}
            />
            <RenderTextArea
              col={{ md: 12, xs: 24 }}
              disabled={true}
              name="placeCall"
              label="Google Place Api Call"
              placeholder="Enter Call"
              autoSize={true}
            />
            <RenderTextArea
              col={{ md: 12, xs: 24 }}
              name="mapApi"
              label="Google Map Api Limit"
              placeholder="Enter Limit"
              autoSize={true}
              rules={[
                {
                  required: true,
                  message: "Please enter numbers only",
                  pattern: /^\d*$/,
                },
              ]}
            />
            <RenderTextArea
              col={{ md: 12, xs: 24 }}
              disabled={true}
              name="mapCall"
              label="Google Map Api Call"
              placeholder="Enter Call"
              autoSize={true}
            />
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
};

export default ApiLimit;
