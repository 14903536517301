import { createGlobalStyle } from 'styled-components';
import { device } from '../Common/breakpoints';

export const ButtonStyle = createGlobalStyle`
.ant-btn{
    height: 2.5em;
    line-height: 100%;
    padding: 0.625em 1.875em;
    border-radius: 8px;
    letter-spacing: 0.5px;
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 500;
    color: var(--black--color);
    /* background-color: var(--white--color); */
    background-color:transparent;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;

    @media screen and (${device?.xs}) {
        height: 2.25em;
        padding:  0.625em 1.25em;
    }

    &:not(:disabled):hover,&:not(:disabled):focus{
        opacity: 0.8;
        color: var(--black--color);
        /* background-color: var(--white--color); */
        background-color:transparent;
    }

    &.ant-btn-primary{
        color: var(--white--color);
        background-color: var(--theme--color);
        &:not(:disabled):hover,&:not(:disabled):focus{
            opacity: 0.9;
            color: var(--white--color);
            background-color: var(--theme--color);
        }
    }
    &.ant-btn-danger{
        color: var(--danger--color);
        border-color: var(--danger--color);
        &:not(:disabled):hover,&:not(:disabled):focus{
            opacity: 0.7;
            color: var(--danger--color);
        }
    }
    &.bg-green{
        color: var(--white--color);
        background-color: #5BA02E;
        &:not(:disabled):hover,&:not(:disabled):focus{
            opacity: 0.9;
            color: var(--white--color);
            background-color: #5BA02E;
        }
    }
    &:disabled{
        background-color: var(--theme--color);
        color: var(--white--color);
    }
    &.outlined{
        border-color: var(--theme--color);
        background-color: transparent;
        color: var(--black--color);
    }
    &.btn-sm{
        font-size: 14px;
        height: 2.25em;
        padding:  0.625em 1.25em;
    }
    &.btn-lg{
        font-size: 16px;
        height: 2.75rem;
    }
    &.btn-xl{
        font-size: 20px;
        height: 3rem;
    }
}
`;
