import { Button, Col, Row } from "antd";
import { Wrapper } from "./Styles";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const ViewTag = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { tagData } = state;

  const tagArray = [
    {
      key: "1",
      name: "Tag Name",
      value: tagData?.tag_name || "-",
    },
    {
      key: "2",
      name: "Label",
      value: tagData?.label_id || "-",
    },
    {
      key: "3",
      name: "Created By",
      value: tagData?.user_full_name || "-",
    },
    {
      key: "4",
      name: "Created On",
      value: tagData.tag_created_at
        ? dayjs(tagData.tag_created_at).format("MM-DD-YYYY")
        : "-",
    },
    {
      key: "5",
      name: "Status",
      value: tagData?.is_public ? "Unhide" : "Hide",
    },
    {
      key: "6",
      name: "Description",
      value: tagData?.tag_description || "-",
    },
  ];

  return (
    <Wrapper>
      <div className="content-wrapper">
        <div className="view-user-btn-section">
          <Button onClick={() => navigate(-1)}>Back</Button>
        </div>
      </div>
      <div className="content-wrapper  mt-16">
        <Row>
          <Col xs={24} className="listWrapper">
            {tagArray?.map(({ name, value, key }) => {
              return (
                <Row key={key} gutter={[0, 0]} className="listItem">
                  <Col xs={3} className="listTitle">
                    {name}
                  </Col>
                  <Col xs={21} className="listText">
                    {value}
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default ViewTag;
