import { createGlobalStyle } from 'styled-components';
import { device } from '../Common/breakpoints';

export const PopoverStyle = createGlobalStyle`

/* COMMON STYLE */
.ant-popover.layout-popover {
    font-family: var(--font-primary);

    .ant-popover-inner {
        background: var(--white--color);
        box-shadow: rgba(100, 116, 139, 0.05) 0px 4px 6px -2px, rgba(100, 116, 139, 0.12) 0px 10px 15px -3px;
    }

    .ant-popover-inner-content {}

    .ant-menu {
        .ant-menu-item {
            border-radius: 6px;
            background-color: transparent;

            &:not(:last-child) {
                margin-bottom: 0;
            }

            transition: background-color 0.4s ease;

            &.ant-menu-item-active {
                background-color: var(--theme--color);
                color: var(--white--color);

                a {
                    color: var(--white--color);
                }
            }

            .ant-menu-title-content {
                font-size: 16px;
                font-family: var(--font-primary);
                font-weight: 500;
                letter-spacing: 0.5px;
            }

            a {
                color: var(--black--color);
            }
        }
    }
}

/* LOGOUT */
.ant-popover.layout-popover {
    z-index: 111;
    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        padding: 8px;
        width: 200px;
    }

    .ant-popover-inner-content {}

    .ant-menu {
        border: none;
        .ant-menu-item {
            border-radius: 6px;
            background-color: transparent;
            height: 2.5rem;
            padding-inline: 1rem;

            &:not(:last-child) {
                margin-bottom: 0;
            }

            transition: background-color 0.4s ease;

            &.ant-menu-item-active {
                background-color: var(--theme--color);
                color: var(--white--color);
                a {
                    color: var(--white--color);
                }
            }

            .ant-menu-title-content {
                /* font-size: 16px;
                font-family: var(--font-primary);
                font-weight: 500;
                letter-spacing: 0.5px; */
                
                @media screen and (${device?.xs}){
                    font-size: 14px;
                }
            }

            a {
                color: var(--black--color);
            }
        }
    }
}

`;
