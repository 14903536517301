import React from "react";
import UserTagsSection from "./userTagsSection";
import ChartSection from "./chartSection";
import RegisteredSection from "./registeredSec";
import { Wrapper } from "../stylesCompany";

const CompanyMetrics = () => {
  return (
    <Wrapper>
      <div className="company-user-tag-sec">
        <UserTagsSection />
      </div>
      <div className="company-chart-section">
        <ChartSection />
      </div>
      <div>
        <RegisteredSection />
      </div>
    </Wrapper>
  );
};

export default CompanyMetrics;
