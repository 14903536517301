import React, { useEffect } from "react";
import { Wrapper } from "./Style";
import { Button, Form, Row } from "antd";
import {
  RenderInput,
  RenderPhoneNumber,
} from "../../components/common/FormField";

import { useDispatch } from "react-redux";
import { validatePhoneNumber } from "../../utils/validation";

import { toastMessage } from "../../components/common/ToastMessage";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import {
  getContactUs,
  updateContactUs,
} from "../../Redux/ContentManagementSlice/action";

const ContactUs = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getContactUs()).then((res) => {
      form.setFieldsValue({
        contact_email: res?.data[0]?.contact_email,
        discord_link: res?.data[0]?.discord_link,
        reddit_link: res?.data[0]?.reddit_link,
        contact_phone: {
          phone: res?.data[0]?.contact_phone,
          code: Number(res?.data[0]?.country_code) || 1,
        },
      });
    });
  }, [dispatch, form]);

  const handleSubmit = (values) => {
    dispatch(
      updateContactUs({
        ...values,
        contact_phone: values.contact_phone.phone,
        country_code: `+${values.contact_phone.code}`,
      })
    )
      .then((res) => {
        toastMessage("success", res?.message);
        navigate(ROUTES.userManagament);
      })
      .catch((err) => {
        toastMessage("error", err?.message);
      });
  };

  return (
    <Wrapper>
      <div className="content-wrapper height-min">
        <Form form={form} onFinish={handleSubmit}>
          <div className="view-profile-btn-section">
            <Button htmlType="submit">Save</Button>
          </div>{" "}
          <div className="text-contact">Contact Us Details</div> <br />
          <Row gutter={[20, 20]}>
            <RenderInput
              col={{ md: 12, xs: 24 }}
              name="contact_email"
              placeholder="Email"
              label="Email Address"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
                {
                  type: "email",
                  message: "Please enter valid email",
                },
              ]}
            />
            <RenderPhoneNumber
              col={{ md: 12, xs: 24 }}
              name="contact_phone"
              type="number"
              maxLength={10}
              onInput={true}
              label="Phone Number"
              placeholder="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please enter phone number",
                },
                { validator: validatePhoneNumber },
              ]}
            />
            <RenderInput
              col={{ md: 12, xs: 24 }}
              name="discord_link"
              placeholder="Enter Link"
              label="Discord Link"
              rules={[
                {
                  required: true,
                  message: "Please enter discord link",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      !/(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error("Please enter valid discord link")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            />
            <RenderInput
              col={{ md: 12, xs: 24 }}
              name="reddit_link"
              placeholder="Enter Link"
              label="Reddit Link"
              rules={[
                {
                  required: true,
                  message: "Please enter reddit link",
                },
                {
                  validator: (_, value) => {
                    if (
                      value &&
                      !/(https?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(
                        value
                      )
                    ) {
                      return Promise.reject(
                        new Error("Please enter valid reddit link")
                      );
                    }

                    return Promise.resolve();
                  },
                },
              ]}
            />
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
};

export default ContactUs;
