import React from "react";
import { Button, Col, Modal, Row } from "antd";

const ConfirmModal = ({
  setIsModalOpen,
  isModalOpen,
  title,
  children,
  fetchFunction,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
    if (fetchFunction) {
      fetchFunction();
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      title={title}
      width={420}
      open={isModalOpen}
      className="common-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      closable={false}
      footer={[
        <Row
          key={1}
          gutter={[20, 10]}
          align="middle"
          justify="center"
          className="footer-wrapper"
        >
          <Col>
            <Button className="outlined" type="primary" onClick={handleCancel}>
              No
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={handleOk}>
              Yes
            </Button>
          </Col>
        </Row>,
      ]}
    >
      <p className="modal-text text-center">{children}</p>
    </Modal>
  );
};

export default ConfirmModal;
