import {
  API_COMPANY_METRIX_CHART,
  API_COMPANY_METRIX_COUNT,
  API_COMPANY_METRIX_REGISTER,
  API_DATE_WISE_COUNT,
  API_TAGS_COUNT,
  API_USER_COUNT,
  API_GET_ERRORS_LIST,
  SESSION_TAG_DETAILS,
} from "../../constants";

export const getUserCount = (data) => ({
  type: "API",
  payload: {
    url: API_USER_COUNT,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getTagsCount = (data) => ({
  type: "API",
  payload: {
    url: API_TAGS_COUNT,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getCompanyMetrixCount = (data) => ({
  type: "API",
  payload: {
    url: API_COMPANY_METRIX_COUNT,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getCompanyMetrixChart = (data) => ({
  type: "API",
  payload: {
    url: API_COMPANY_METRIX_CHART,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getCompanyMetrixRegister = (data) => ({
  type: "API",
  payload: {
    url: API_COMPANY_METRIX_REGISTER,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getDateWiseCount = (data) => ({
  type: "API",
  payload: {
    url: API_DATE_WISE_COUNT,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getErrorsList = (params) => ({
  type: "API",
  payload: {
    url: API_GET_ERRORS_LIST + params,
    method: "GET",
    hideLoader: false,
  },
});

export const getSessionDetails = (data) => ({
  type: "API",
  payload: {
    url: SESSION_TAG_DETAILS,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});
