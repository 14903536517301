import React from 'react';
import ComponentsStyle from './Components';
import CommonTheme from './Common';
import { ThemeStyle } from './Theme';

const GlobalStyle = () => {
  return (
    <>
      <ThemeStyle />
      <ComponentsStyle />
      <CommonTheme />
    </>
  );
};

export default GlobalStyle;
