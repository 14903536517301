import React, { useContext, useEffect, useState } from "react";
import { getCompanyMetrixCount } from "../../../Redux/DashboardSlice/action";
import { useDispatch } from "react-redux";
import { Wrapper } from "../stylesCompany";
import { Button, Card, Tooltip } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { TooltipIcon } from "../../../svg";
import { DateContext } from "..";

const MetricCard = ({ title, value, errorPageData }) => {
  const navigate = useNavigate();
  return (
    <Card className="company-card-user">
      <p>{title}</p>
      {/* {showIcon && (
        <Tooltip
          title={iconDesc}
          placement="topRight"
          overlayInnerStyle={{ width: "270px" }}
        >
          <div className="icon-tool">
            <TooltipIcon />
          </div>
        </Tooltip>
      )} */}
      <span className="metric-val">{value}</span>
      {errorPageData && (
        <div>
          <Button
            onClick={() => navigate(ROUTES.errorPage)}
            type="primary"
            size="small"
          >
            View URLs
          </Button>
        </div>
      )}
    </Card>
  );
};

const UserTagsSection = () => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useContext(DateContext);

  const [companyMetrixData, setCompanyMetrixData] = useState({});

  useEffect(() => {
    dispatch(getCompanyMetrixCount({ startDate: startDate, endDate: endDate }))
      .then((res) => setCompanyMetrixData(res?.data))
      .catch(() => {
        setCompanyMetrixData({});
      });
  }, [dispatch, startDate, endDate]);

  const metricsData = React.useMemo(
    () => [
      {
        title: "Total Tags Created",
        value: companyMetrixData?.tagsSearchedCount?.totaltags || 0,
        errorPageData: false,
      },
      {
        title: "Total Errors",
        value: companyMetrixData?.totalerrorsCount || 0,
        errorPageData: true,
      },
      {
        title: "New Users",
        value: companyMetrixData?.userCntDetails?.newUserCnt || 0,
        errorPageData: false,
      },
      {
        title: "Active Users",
        value: companyMetrixData?.userCntDetails?.activeUserCnt || 0,
        errorPageData: false,
      },
      {
        title: "Inactive Users",
        value: companyMetrixData?.userCntDetails?.inactiveUserCnt || 0,
        errorPageData: false,
      },
      {
        title: "Growth Rate",
        value: companyMetrixData?.userCntDetails?.growthRate?.toFixed(2) || 0,
        errorPageData: false,
      },
    ],
    [companyMetrixData]
  );

  return (
    <Wrapper>
      <div className="company-card-div">
        {metricsData?.map((metric) => (
          <MetricCard
            key={metric.title}
            title={metric.title}
            value={metric.value}
            errorPageData={metric.errorPageData}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default UserTagsSection;
