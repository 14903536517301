import React, { useState } from "react";
import { RenderInput } from "../../components/common/FormField";
import { useNavigate } from "react-router";
import { Button, Form, Space, Table, Tag } from "antd";
import { ROUTES } from "../../constants/routes";
import { useDispatch } from "react-redux";
import { usePaginationTable } from "../../components/common/Table";
import ConfirmModal from "../../components/common/Modal/ConfirmModal";
import { Wrapper } from "./Styles";
import { debounce } from "../../utils/utilFunctions";
import { toastMessage } from "../../components/common/ToastMessage";
import { ViewIcon, DeleteIcon, InActiveIcon, ActiveIcon } from "../../svg";
import {
  changeTagStatus,
  deleteTagAction,
  getTagsList,
} from "../../Redux/TagSlice/action";
import dayjs from "dayjs";

const TagsManagement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [statusData, setStatusData] = useState({});
  const [searchInput, setSearchInput] = useState("");

  const fetchData = async (requestArgs) => {
    const { current, pageSize, sortBy, sortOrder, search } = requestArgs;

    let params = `?search=${search || ""}&page=${current || 1}&size=${
      pageSize || 10
    }&sort_by=${sortBy ? `${sortBy}` : ""}&sort_order=${sortOrder || ""}`;

    const response = await dispatch(getTagsList(params)).catch((err) =>
      console.error(err)
    );
    return { data: response?.data?.list, total: response?.data?.total_count };
  };

  const { handleTableChange, data, loading, pagination, refetch } =
    usePaginationTable(fetchData, searchInput);

  const onDeleteClick = (record) => {
    setIsModalOpen(true);
    setDeleteId(record?.id);
  };

  const onStatusClick = (record) => {
    setIsStatusOpen(true);
    setStatusData(record);
  };

  const deleteTag = () => {
    dispatch(deleteTagAction(deleteId))
      .then(() => {
        refetch();
        toastMessage("success", "Tag deleted successfully");
      })
      .catch((err) => toastMessage("error", err?.message));
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const changeStatus = () => {
    let params = {
      id: statusData?.id,
      status: statusData?.is_public ? "hide" : "unhide",
    };
    dispatch(changeTagStatus(params))
      .then((res) => {
        if (res?.statusCode === 200) {
          let successMsg = statusData?.is_public
            ? "Tag hide successfully"
            : "Tag unhide successfully";
          refetch();
          toastMessage("success", successMsg);
        }
      })
      .catch((err) => toastMessage("success", err?.message));
  };

  const debouncedSearchHandler = debounce(handleSearchChange, 300);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "serialNumber",
      render: (_text, _record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Tag Name",
      dataIndex: "tag_name",
      key: "tag_name",
      sortKey: "tag_name",
      render: (row, record) => (
        <>{record.tag_name + (record.tag_address || "")}</>
      ),
      sorter: (a, b) => a?.tag_name - b?.tag_name,
    },
    {
      title: "Label",
      dataIndex: "label_id",
      key: "label_id",
      sortKey: "label_id",
      render: (row, record) => <>{record.label_id || "-"}</>,
      sorter: (a, b) => a?.label_id - b?.label_id,
    },
    {
      title: "Description",
      dataIndex: "tag_description",
      key: "tag_description",
      sortKey: "tag_description",
      render: (row, record) => <>{record.tag_description || "-"}</>,
      sorter: (a, b) => a?.tag_description - b?.tag_description,
    },
    {
      title: "Created By",
      dataIndex: "user_email_address",
      key: "user_email_address",
      sortKey: "user_email_address",
      sorter: (a, b) => a?.user_email_address - b?.user_email_address,
      render: (_row, record) => (
        <>
          {`${record.user_email_address}
          ${record?.user_full_name ? `(${record?.user_full_name})` : ""}`}
        </>
      ),
    },
    {
      title: "Created On",
      dataIndex: "tag_created_at",
      key: "tag_created_at",
      sortKey: "tag_created_at",
      sorter: (a, b) => a?.tag_created_at - b?.tag_created_at,
      render: (row, record) => (
        <div>
          <div>
            {record.tag_created_at
              ? dayjs(record.tag_created_at).format("MM-DD-YYYY")
              : "-"}
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_public",
      key: "is_public",
      render: (row, record) => {
        return (
          <div className="status-col">
            <Tag
              style={{ borderRadius: "11px" }}
              color={record?.is_public ? "error" : "success"}
            >
              {record?.is_public ? "Unhide" : "Hide"}
            </Tag>
          </div>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 150,
      render: (row, record) => {
        return (
          <Space size={1}>
            <Button
              htmlType="button"
              className="view-btn"
              onClick={() =>
                navigate(ROUTES.viewTag, { state: { tagData: record } })
              }
            >
              <ViewIcon />
            </Button>
            <Button
              onClick={() => onDeleteClick(record)}
              htmlType="button"
              className="view-btn"
            >
              <DeleteIcon />
            </Button>
            <Button
              onClick={() => onStatusClick(record)}
              htmlType="button"
              className="action-btn"
            >
              {record?.is_public ? <ActiveIcon /> : <InActiveIcon />}
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <div className="content-wrapper">
        <Form>
          <RenderInput
            col={{ md: 8, xs: 24 }}
            name="search"
            placeholder="Search Tags"
            onChange={debouncedSearchHandler}
          />
        </Form>
      </div>{" "}
      <br />
      <div className="content-wrapper">
        <div className="shadow-paper">
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
            scroll={{ x: 400 }}
          />
        </div>
      </div>
      {isModalOpen && (
        <ConfirmModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          title={`Are you sure you want to delete this tag ?`}
          fetchFunction={deleteTag}
        ></ConfirmModal>
      )}
      {isStatusOpen && (
        <ConfirmModal
          setIsModalOpen={setIsStatusOpen}
          isModalOpen={isStatusOpen}
          title={`Are you sure you want to ${
            statusData?.is_public ? "hide" : "unhide"
          } the tag status?`}
          fetchFunction={changeStatus}
        ></ConfirmModal>
      )}
    </Wrapper>
  );
};

export default TagsManagement;
