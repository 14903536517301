import { configureStore } from '@reduxjs/toolkit';
import reduxApiMiddleware from './Middleware';
import thunk from 'redux-thunk';
import AuthSlice from './AuthSlice';
import UserManagementSlice from './UserManagementSlice';

import ContentManagementSlice from './ContentManagementSlice';


export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    userManagement: UserManagementSlice,
    contentManagement: ContentManagementSlice,
  },
  middleware: [thunk, reduxApiMiddleware]
});
