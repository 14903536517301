import React from 'react';
import { ErrorWrapper } from './Style';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(_error) {
    // It will update the state so the next render shows the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, _info) {
    // It will catch error in any component below. We can also log the error to an error reporting service.
    console.error('error : ', error);
  }
  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <div className="heading">
            <h1>Oops , Something is wrong ...</h1>
            <h3>Please Refresh</h3>
          </div>
          <div className="content">
            <a href="/">
              <button className="glow-on-hover" type="button">
                Go Back
              </button>
            </a>
          </div>
        </ErrorWrapper>
      );
    }
    return this.props.children;
  }
}
