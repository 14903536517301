import React, { useEffect } from "react";
import { Wrapper } from "../Style";
import { Button, Form, Row } from "antd";
import { RenderTextArea } from "../../../components/common/FormField";
import { useLocation, useNavigate } from "react-router-dom";
import { editFaq } from "../../../Redux/ContentManagementSlice/action";
import { useDispatch } from "react-redux";
import { toastMessage } from "../../../components/common/ToastMessage";
import { ROUTES } from "../../../constants/routes";
import { validateWhiteSpace } from "../../../utils/validation";

const EditFaq = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      question: state?.faqData?.question,
      answer: state?.faqData.answer,
    });
  }, [form, state?.faqData.answer, state?.faqData?.question]);

  const handleSubmit = (values) => {
    let id = state?.faqData?.id;
    let requestPayload = {
      question: values?.question,
      answer: values?.answer,
    };
    dispatch(editFaq(requestPayload, id))
      .then(() => {
        toastMessage("success", "FAQs updated successfully");
        navigate(ROUTES.faq);
      })
      .catch((err) => toastMessage("error", err?.message));
  };

  return (
    <Wrapper>
      <div className="content-wrapper height-min">
        <Form form={form} onFinish={handleSubmit}>
          <div className="faq-btn-section">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
          <br />
          <Row gutter={[20, 20]}>
            <RenderTextArea
              col={{ md: 24, xs: 24 }}
              name="question"
              label="Question"
              placeholder="Enter Question"
              autoSize={true}
              rules={[
                {
                  required: true,
                  message: "Please enter question",
                },
                { validator: validateWhiteSpace },
              ]}
            />
            <RenderTextArea
              col={{ md: 24, xs: 24 }}
              name="answer"
              label="Answer"
              placeholder="Enter Answer"
              autoSize={true}
              rules={[
                {
                  required: true,
                  message: "Please enter answer",
                },
                { validator: validateWhiteSpace },
              ]}
            />
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
};

export default EditFaq;
