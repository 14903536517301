import styled from "styled-components";

export const Wrapper = styled.div`
  .view-user-btn-section {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;
  }
  .user-badge-data {
    justify-content: space-around;
  }
  .badge-data-card {
    width: 250px;
    height: 70px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .label {
    height: auto;
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: 14px;
    color: var(--black--color);
  }
  .status-col {
    cursor: pointer;
  }
  .label-space {
    margin-bottom: 10px;
  }
  .placeholder {
    font-family: var(--font-inter);
    font-weight: 200;
    font-size: 14px;
    color: var(--gray--color);
  }
  .ant-select.readOnly {
    pointer-events: none;
  }
  .card-font {
    background: var(--white--color);
    border: 1px solid var(--border--color);
    font-family: var(--font-inter);
    font-weight: 400;
    font-size: 14px;
    color: var(--black--color);
  }
  .listItem {
    padding: 15px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .listTitle {
    color: var(--character-title-85, #223047);
    font-family: var(--font-inter);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
  }
  .listText {
    color: var(--character-title-85, #223047);
    font-family: var(--font-inter);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }
`;
