import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { LOGIN_F, LOGIN_S, LS_AUTHTOKEN, LS_USER } from "../../constants";

const initialState = {
  // Global loader for api
  isLoading: false,

  // Auth Data
  isLoggedIn: false,
  userData: {},
};

// Reducer
const loginSlice = createSlice({
  name: "login",
  initialState: initialState,
  reducers: {
    loaderChange: (state, payload) => {
      state.isLoading = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LOGIN_S, (state, action) => {
      // Default header for auth
      axios.defaults.headers.common["Authorization"] =
        // eslint-disable-next-line no-useless-concat
        "Bearer" + " " + action.payload.data.access_token;
      localStorage.setItem(
        LS_AUTHTOKEN,
        JSON.stringify(action.payload.data.access_token)
      );
      localStorage.setItem(LS_USER, JSON.stringify(action.payload.data));

      state.userData = action.payload;
      state.isLoggedIn = true;
    });
    builder.addCase(LOGIN_F, (state) => {
      // remove items on logout
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem(LS_AUTHTOKEN);
      localStorage.removeItem(LS_USER);

      state.userData = {};
      state.isLoggedIn = false;
    });
  },
});

export const { loaderChange } = loginSlice.actions;
export default loginSlice.reducer;

export const selectUserData = (state) => state.auth.userData;
