import { GET_MAP_TYPE, UPDATE_MAP_TYPE } from "../../constants";

export const updateMapType = (data) => ({
  type: "API",
  payload: {
    url: UPDATE_MAP_TYPE,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getMapType = () => ({
  type: "API",
  payload: {
    url: GET_MAP_TYPE,
    method: "GET",
    hideLoader: false,
  },
});
