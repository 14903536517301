import React, { useContext, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import {
  getDateWiseCount,
  getTagsCount,
} from "../../../Redux/DashboardSlice/action";
import { useDispatch } from "react-redux";
import { Badge } from "antd";
import { Wrapper } from "../styles";
import { DateContext } from "..";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function ChartSection() {
  const dispatch = useDispatch();

  const { startDate, endDate, active } = useContext(DateContext);

  const [tagsCountData, setTagsCountData] = useState({});
  const [dateWiseTag, setDateWiseTag] = useState({});

  const [labels, setLabels] = useState([]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: dateWiseTag?.length
          ? dateWiseTag?.map((val, i) => val?.tag_count)
          : [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  useEffect(() => {
    dispatch(getDateWiseCount({ startDate: startDate, endDate: endDate }))
      .then((res) => {
        const responseData = res?.data?.tagscreatedDateWise || [];

        let generatedLabels;
        if (active === null || active === undefined || active === 4) {
          generatedLabels = responseData?.length
            ? responseData?.map((val) =>
                dayjs(val?.tag_creation_date).format("DD MMM")
              )
            : [];
          setDateWiseTag(responseData);
        } else if (responseData?.length === 1) {
          generatedLabels = [dayjs().format("DD")];
          setDateWiseTag(responseData);
        } else if (responseData?.length === 7) {
          generatedLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
          setDateWiseTag(responseData);
        } else {
          const conditionalDate = dayjs(startDate).endOf("month").format("DD");
          generatedLabels = [
            "01-05",
            "06-10",
            "11-15",
            "16-20",
            "21-25",
            `26-${conditionalDate}`,
          ];
          if (responseData?.length) {
            const groupedByFive = [];
            let temp = 0;
            let temp2 = false;

            responseData.forEach((element, ind) => {
              if (ind === 30) temp2 = true;
              if (ind !== 0 && ind % 5 === 0) {
                groupedByFive.push({ tag_count: temp });
                temp = 0;
              }
              temp += element.tag_count;
            });
            if (temp2 === false) groupedByFive.push({ tag_count: temp });

            setDateWiseTag(groupedByFive);
          }
        }
        setLabels(generatedLabels);
      })
      .catch(() => {
        setDateWiseTag({});
      });
    dispatch(getTagsCount({ startDate: startDate, endDate: endDate }))
      .then((res) => {
        setTagsCountData(res?.data);
      })
      .catch(() => {
        setTagsCountData({});
      });
  }, [dispatch, startDate, endDate]);

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > dayjs().endOf("day");
  };

  return (
    <Wrapper>
      <div className="chart-user">
        <div className="chart-sec">
          <Bar options={options} data={data} />
        </div>
        <div className="chart-axis-sec">
          <div>X axis - Date</div>
          <div>Y axis - Total Lab Tests</div>
        </div>
        <div className="highest-tag">
          <div className="title-high">Highest Tag Created Per User</div>
          <ul>
            {tagsCountData?.tagscreatedUserWise?.length
              ? tagsCountData?.tagscreatedUserWise
                  ?.slice(0, 5)
                  ?.map((val, i) => {
                    return (
                      <li className="list-user" key={i}>
                        <Badge count={i + 1} />
                        <div>
                          <span className="full-name">
                            {val?.user_email_address}
                          </span>{" "}
                          {val?.user_full_name && (
                            <span>({val?.user_full_name})</span>
                          )}
                        </div>
                        <span>{val?.tag_count}</span>
                      </li>
                    );
                  })
              : "No Data Available"}
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}
