import { createGlobalStyle } from 'styled-components';
import { device } from '../Common/breakpoints';

export const CommonTable = createGlobalStyle`

    .search-group {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .ant-form-item{
            max-width: 350px;
            width: 100%;
        }
    }

    .ant-table-wrapper {
        table{
            border: 1px solid var(--border--color);
            border-radius:0px;
            overflow: hidden;
        }
        .ant-table-thead{
            tr{
                th{
                    font-family: var(--font-inter);
                    font-size: 16px;
                    font-weight: 600;
                    color: var(--black--color);
                    background-color: var(--white--color);
                    border-bottom: 1px solid var(--border--color);
                    border-right: 1px solid var(--border--color);
                }
                th{
                    &:last-child{
                        border-right: none;
                    }
                }
            }
        }
        .ant-table-tbody{
            tr{
              td{
                    font-family: var(--font-inter);
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--tabletext--color);
                    border-bottom: 1px solid var(--border--color);
                    border-right: 1px solid var(--border--color);
                    &:last-child{
                        border-right: none;
                    }
                }
                &:last-child{
                    td{
                        border-bottom: none;
                    }
                }
            }
        }
        .image{
            picture{
                width: 42px;
                height: 42px;
                display: flex;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }
        }
        
        .ant-space{
            .ant-space-item{
                padding-right: 10px;
                border-right: 1px solid var(--border--color);
                .ant-btn{
                    padding: 0px;
                    height: auto;
                    border: none;
                    box-shadow: none;
                    font-family: var(--font-inter);
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--theme--color);
                }
                &:last-child{
                    padding-right: 0;
                    border-right: none;
                }
            }
        }
    }

    /* --- Pagination --- */
    .pagination{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    .ant-pagination{
        .ant-pagination-item{
            width: 35px;
            height: 35px;
            border: 1px solid var(--border--color);
            font-family: var(--font-secondary);
            font-size: 14px;
            font-weight: 600;
            color: var(--black--color);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--white--color);
            a{
                color: var(--black--color);
            }
            &.ant-pagination-item-active{
                background-color: var(--theme--color);
                border: 1px solid var(--theme--color);
                color: var(--white--color);
                a{
                    color: var(--white--color);
                }
            }
            &:hover,&.ant-pagination-item:not(.ant-pagination-item-active):hover{
                background-color: var(--theme--color);
                border: 1px solid var(--theme--color);
                color: var(--white--color);
                a{
                    color: var(--white--color);
                }
            }
        }
    }

    /* --- Switch --- */
    .ant-switch{
        &.ant-switch-checked,&.ant-switch-checked:hover:not(.ant-switch-disabled){
            background: var(--theme--color);
        }        
    }


    @media only screen and (${device?.sm}) {

        .search-group {
            margin-bottom: 30px;
            justify-content: flex-end;
            .ant-form-item{
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    

`;
