import { CommonStyle } from './common';
import { ResetStyle } from './reset';
import { Variables } from './variable';

const CommonTheme = () => {
  return (
    <>
      <CommonStyle />
      <ResetStyle />
      <Variables />
    </>
  );
};

export default CommonTheme;
