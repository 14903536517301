import { createGlobalStyle } from 'styled-components';
import { device } from './breakpoints';

export const CommonStyle = createGlobalStyle`
.container-fluid {width: 100%;max-width: 100%;padding-left: 15px;padding-right: 15px;margin: 0 auto;}
.container { width: 100%; max-width: 1230px; padding-left: 15px; padding-right: 15px; margin: 0 auto;
	&.lg { width: 1290px; }
	&.md { width: 908px; }
}

/* Fonts Size */
h1 {font-size: 45px;}
h2 {font-size: 34px;}
h3 {font-size: 28px;}
h4 {font-size: 24px;}
h5 {font-size: 20px;}
p {font-size: 18px;}

/* Text Alignment */
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}

/* Text Colors */
.text-success {color: var(--success--color);}
.text-danger {color: var(--danger--color);}
.text-warning {color: var(--warning--color);}
.text-info {color: var(--info--color);}

/* Width css */
.w-100 {width: 100%;}
.max-w-100 {max-width:100%;}
.h-100 {height: 100%;}
.h-100vh {min-height: 100vh;}

/* Margin */
.m-0 { margin: 0; }
.m-0-imp { margin: 0 !important; }
.margin-auto { margin: 0; }
.my-auto { margin-top:auto; margin-bottom:auto; }
.mx-auto { margin-left:auto; margin-right:auto; }

.mt-0 { margin-top: 0; }
.mt-0-imp { margin-top: 0 !important;}
.mt-auto { margin-top: auto;}
.mt-6 { margin-top: 6px; }
.mt-8 { margin-top: 8px; }
.mt-12 { margin-top: 12px; }
.mt-16 { margin-top: 16px; }
.mt-10 { margin-top: 10px; }
.mt-18 { margin-top: 18px; }
.mt-20 { margin-top: 20px; }
.mt-24 { margin-top: 24px; }
.mt-30 { margin-top: 30px; }
.mt-32 { margin-top: 32px; }
.mt-36 { margin-top: 36px; }
.mt-40 { margin-top: 40px; }
.mt-48 { margin-top: 48px; }

.mr-0 { margin-right: 0; }
.mr-0-imp { margin-right: 0 !important;}
.mr-auto { margin-right: auto;}
.mr-6 { margin-right: 6px; }
.mr-8 { margin-right: 8px; }
.mr-12 { margin-right: 12px; }
.mr-16 { margin-right: 16px; }
.mr-10 { margin-right: 10px; }
.mr-18 { margin-right: 18px; }
.mr-20 { margin-right: 20px; }
.mr-24 { margin-right: 24px; }
.mr-30 { margin-right: 30px; }
.mr-32 { margin-right: 32px; }
.mr-36 { margin-right: 36px; }
.mr-40 { margin-right: 40px; }
.mr-48 { margin-right: 48px; }

.mb-0 { margin-bottom: 0; }
.mb-0-imp { margin-bottom: 0 !important;}
.mb-auto { margin-bottom: auto;}
.mb-6 { margin-bottom: 6px; }
.mb-8 { margin-bottom: 8px; }
.mb-12 { margin-bottom: 12px; }
.mb-16 { margin-bottom: 16px; }
.mb-10 { margin-bottom: 10px; }
.mb-18 { margin-bottom: 18px; }
.mb-20 { margin-bottom: 20px; }
.mb-24 { margin-bottom: 24px; }
.mb-30 { margin-bottom: 30px; }
.mb-32 { margin-bottom: 32px; }
.mb-36 { margin-bottom: 36px; }
.mb-40 { margin-bottom: 40px; }
.mb-48 { margin-bottom: 48px; }

.ml-0 { margin-left: 0; }
.ml-0-imp { margin-left: 0 !important;}
.ml-auto { margin-left: auto;}
.ml-6 { margin-left: 6px; }
.ml-8 { margin-left: 8px; }
.ml-12 { margin-left: 12px; }
.ml-16 { margin-left: 16px; }
.ml-10 { margin-left: 10px; }
.ml-18 { margin-left: 18px; }
.ml-20 { margin-left: 20px; }
.ml-24 { margin-left: 24px; }
.ml-30 { margin-left: 30px; }
.ml-32 { margin-left: 32px; }
.ml-36 { margin-left: 36px; }
.ml-40 { margin-left: 40px; }
.ml-48 { margin-left: 48px; }

/* Padding */
.p-0 { padding: 0; }
.p-0-imp { padding: 0 !important; }

.pt-0 { padding-top: 0; }
.pt-0-imp { padding-top: 0 !important;}
.pt-6 { padding-top: 6px; }
.pt-8 { padding-top: 8px; }
.pt-12 { padding-top: 12px; }
.pt-16 { padding-top: 16px; }
.pt-10 { padding-top: 10px; }
.pt-18 { padding-top: 18px; }
.pt-20 { padding-top: 20px; }
.pt-24 { padding-top: 24px; }
.pt-30 { padding-top: 30px; }
.pt-32 { padding-top: 32px; }
.pt-36 { padding-top: 36px; }
.pt-40 { padding-top: 40px; }
.pt-48 { padding-top: 48px; }

.pr-0 {padding-right: 0; }
.pr-0-imp {padding-right: 0 !important;}
.pr-6 {padding-right: 6px; }
.pr-8 {padding-right: 8px; }
.pr-12 {padding-right: 12px; }
.pr-16 {padding-right: 16px; }
.pr-10 {padding-right: 10px; }
.pr-18 {padding-right: 18px; }
.pr-20 {padding-right: 20px; }
.pr-24 {padding-right: 24px; }
.pr-30 {padding-right: 30px; }
.pr-32 {padding-right: 32px; }
.pr-36 {padding-right: 36px; }
.pr-40 {padding-right: 40px; }
.pr-48 {padding-right: 48px; }

.pb-0 { padding-bottom: 0; }
.pb-0-imp { padding-bottom: 0 !important;}
.pb-6 { padding-bottom: 6px; }
.pb-8 { padding-bottom: 8px; }
.pb-12 { padding-bottom: 12px; }
.pb-16 { padding-bottom: 16px; }
.pb-10 { padding-bottom: 10px; }
.pb-18 { padding-bottom: 18px; }
.pb-20 { padding-bottom: 20px; }
.pb-24 { padding-bottom: 24px; }
.pb-30 { padding-bottom: 30px; }
.pb-32 { padding-bottom: 32px; }
.pb-36 { padding-bottom: 36px; }
.pb-40 { padding-bottom: 40px; }
.pb-48 { padding-bottom: 48px; }

.pl-0 { padding-left: 0; }
.pl-0-imp { padding-left: 0 !important;}
.pl-6 { padding-left: 6px; }
.pl-8 { padding-left: 8px; }
.pl-12 { padding-left: 12px; }
.pl-16 { padding-left: 16px; }
.pl-10 { padding-left: 10px; }
.pl-18 { padding-left: 18px; }
.pl-20 { padding-left: 20px; }
.pl-24 { padding-left: 24px; }
.pl-30 { padding-left: 30px; }
.pl-32 { padding-left: 32px; }
.pl-36 { padding-left: 36px; }
.pl-40 { padding-left: 40px; }
.pl-48 { padding-left: 48px; }

.md{background: none !important}

.infinite-scroll-component__outerdiv{
    width: 100%;
}

@media only screen and (${device?.sm}) {
    h1 {font-size: 35px;}
    h2 {font-size: 28px;}
    h3 {font-size: 24px;}
    h4 {font-size: 20px;}
    h5 {font-size: 16px;}
    p {font-size: 16px;}

    .search-group {
        margin-bottom: 30px;
        justify-content: flex-end;
        .ant-form-item{
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
}
`;
