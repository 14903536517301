import { createGlobalStyle } from 'styled-components';

export const ImageStyle = createGlobalStyle`
.ant-image {
    position: relative;
    display: inline-block;
    height: 100%;
}
.ant-image-img {
width: 100%;
height: 100% !important;
vertical-align: middle;
}
`;
