import React from 'react';
import { PopoverStyle } from './Popover.Style';
import { ModalStyle } from './Modal.Style';
import { ButtonStyle } from './Button.Style';
import { FormStyle } from './Form.Style';
import { CommonTable } from './Table.Style';
import { ImageStyle } from './Image.Style';

const ComponentsStyle = () => {
  return (
    <>
      <PopoverStyle />
      <ModalStyle />
      <ButtonStyle />
      <FormStyle />
      <ImageStyle />
      <CommonTable />
    </>
  );
};

export default ComponentsStyle;
