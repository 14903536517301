import React, { useState } from "react";
import UserTagsSection from "./userTagsSection";
import ChartSection from "./chartSection";
import { Wrapper } from "../styles";
import RegisteredSection from "./registeredSec";

const UserMetrics = () => {
  const [registerGuestUserCount, setRegisterGuestUserCount] = useState({
    registerCount: 0,
    guestCount: 0,
  });
  return (
    <Wrapper>
      <div className="user-tag-sec">
        <UserTagsSection
          setRegisterGuestUserCount={setRegisterGuestUserCount}
        />
      </div>
      <div className="chart-section">
        <ChartSection />
      </div>
      <div>
        <RegisteredSection registerGuestUserCount={registerGuestUserCount} />
      </div>
    </Wrapper>
  );
};

export default UserMetrics;
