import React, { useEffect, useState } from "react";
import { RenderRadioGroup } from "../../components/common/FormField";
import { Form } from "antd";
import { getMapType, updateMapType } from "../../Redux/MapTypeSlice/action";
import { useDispatch } from "react-redux";
import { Wrapper } from "./style";
import { toastMessage } from "../../components/common/ToastMessage";

const MapType = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [callAfterApiSuccess, setCallAfterApiSuccess] = useState(false);
  const [mapTypeOptions, setMapTypeOptions] = useState([]);

  useEffect(() => {
    dispatch(getMapType())
      .then((res) => {
        const mappedOptions = res?.data?.map((obj) => ({
          label: obj?.map_type === "google_map" ? "Google Maps" : "Mapbox",
          value: obj?.map_type,
          is_selected: obj?.is_selected,
        }));
        setMapTypeOptions(mappedOptions);
        const selectedOption = mappedOptions.find(
          (option) => option.is_selected
        );
        form.setFieldsValue({
          mapType: selectedOption?.value,
        });
      })
      .catch((_err) => {});
  }, [dispatch, callAfterApiSuccess, form]);

  const onMapTypeChange = (e) => {
    dispatch(
      updateMapType({
        map_type: e.target.value,
        is_selected: e.target.checked,
      })
    )
      .then((res) => {
        toastMessage("success", res?.message);
        setCallAfterApiSuccess((prev) => !prev);
      })
      .catch((err) => {
        toastMessage("error", err?.message);
      });
  };

  return (
    <Wrapper>
      <div className="content-wrapper height-min">
        <Form form={form}>
          <RenderRadioGroup
            col={{ md: 12, xs: 24 }}
            label="Please Select Map Type"
            name="mapType"
            options={mapTypeOptions}
            onChange={onMapTypeChange}
          />
        </Form>
      </div>
    </Wrapper>
  );
};

export default MapType;
