import { UPDATE_API_LIMIT, GET_API_LIMIT } from "../../constants";

export const updateApiLimit = (data) => ({
  type: "API",
  payload: {
    url: UPDATE_API_LIMIT,
    method: "POST",
    data: data,
    hideLoader: false,
  },
});

export const getApiLimit = () => ({
  type: "API",
  payload: {
    url: GET_API_LIMIT,
    method: "GET",
    hideLoader: false,
  },
});
