import React, { useEffect, useState } from "react";
import { Wrapper } from "./Style";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Button } from "antd";
import { toastMessage } from "../../components/common/ToastMessage";
import { useDispatch } from "react-redux";
import {
  editCmsContent,
  getCmsList,
} from "../../Redux/ContentManagementSlice/action";

const PrivacyPolicy = () => {
  const [value, setValue] = useState("");
  const [callApiAfterSuccess, setCallApiAfterSuccess] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCmsList())
      .then((res) => {
        setValue(res?.data?.list?.[2]?.value);
      })
      .catch((err) => console.log(err));
  }, [dispatch, callApiAfterSuccess]);

  const handleSubmit = () => {
    if (!value) {
      return toastMessage("error", "Please enter description");
    }
    const id = 2;
    let requestPayload = {
      id,
      value,
    };
    dispatch(editCmsContent(requestPayload))
      .then(() => {
        setCallApiAfterSuccess((prev) => !prev);
        toastMessage("success", "Privacy Policy updated successfully");
      })
      .catch((err) => console.log(err));
  };

  return (
    <Wrapper>
      <div className="content-wrapper">
        <div className="justify-content-between height-min">
          <ReactQuill
            className="quill"
            theme="snow"
            value={value}
            onChange={setValue}
          />
          <Button type="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default PrivacyPolicy;
