import { Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DateContext } from "..";
import { getSessionDetails } from "../../../Redux/DashboardSlice/action";

const RegisteredSection = ({ registerGuestUserCount }) => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useContext(DateContext);

  const [sessionData, setSessionData] = useState({});

  useEffect(() => {
    dispatch(getSessionDetails({ startDate: startDate, endDate: endDate }))
      .then((res) => setSessionData(res?.data))
      .catch(() => setSessionData({}));
  }, [dispatch, startDate, endDate]);

  const columns = [
    {
      title: "Total Number of Sessions",
      dataIndex: "session",
      key: "session",
    },
    {
      title: "Average Time Spent per Session",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Average Label Search",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Average Tag Search",
      dataIndex: "tag",
      key: "tag",
    },
  ];
  const data = [
    {
      session: sessionData?.sessions?.totalSessionCnt || 0,
      time: sessionData?.sessions?.avtTimePersession
        ? `${(sessionData?.sessions?.avtTimePersession / 60000)?.toFixed(
            2
          )} minutes`
        : "0 minutes",
      label: sessionData?.avglabelSearchedCnt?.toString()?.includes(".")
        ? sessionData?.avglabelSearchedCnt?.toFixed(2)
        : sessionData?.avglabelSearchedCnt,
      tag: sessionData?.avgtagSearchedCnt?.toString()?.includes(".")
        ? sessionData?.avgtagSearchedCnt?.toFixed(2)
        : sessionData?.avgtagSearchedCnt,
    },
  ];

  return (
    <div className="main-register">
      <div className="register-guest-count">
        <div className="register-count">
          <p>Registered Users</p>
          <div>{registerGuestUserCount?.registerCount || 0}</div>
        </div>
        <div className="guest-count">
          <p>Guest Users</p>
          <div>{registerGuestUserCount?.guestCount || 0}</div>
        </div>
      </div>
      <div>
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
    </div>
  );
};

export default RegisteredSection;
