export const ROUTES = {
  default: `/`,
  login: `/login`,
  forgotPassword: `/forgot-password`,
  resetPassword: `/resetPassword/:id`,
  changePassword: `/change-password`,
  myAccount: `/my-account`,
  profile: `/profile`,
  userManagament: `/user-management`,
  viewUser: `/user-management/view`,
  cms: `/cms-management`,
  feedback: `/feedback`,
  viewFeedback: `/feedback/view`,
  terms: `/terms-conditions`,
  faq: `/faq`,
  contactUs: `/contact-us`,
  addFaq: `/addFaq`,
  editFaq: `/editFaq`,
  tags: `/tags`,
  viewTag: `/tags/view-tag`,
  privacyPolicy: `/privacy-policy`,
  communityGuidelines: `/community-guidelines`,
  betaAgreement: `/beta-agreement`,
  dashboard: `/dashboard`,
  apiLimit: `/api-limit`,
  errorPage: `/404-error-URLs`,
  mapType: `/map-type`,
};
