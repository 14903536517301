import { useEffect, useState } from "react";

const initialState = {
  current: 1,
  pageSize: 10,
  sortOrder: "",
  sortBy: "",
  search: "",
  total: 0,
};

export const usePaginationTable = (fetchData, searchData) => {
  const [data, setData] = useState([]);
  const [refetchTrigger, setrefetchTrigger] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(initialState);

  useEffect(() => {
    if (searchData?.length > 0) {
      handleSearch();
    } else {
      handleTableChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData, refetchTrigger]);

  const refetch = () => setrefetchTrigger(!refetchTrigger);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await fetchData({
        ...pagination,
        current: 1,
        search: searchData,
      });
      const { data, total } = response;
      setData(data);
      setPagination({ ...pagination, current: 1, total, search: searchData });
    } catch (error) {
      console.error("Error searching data:", error);
    }
    setLoading(false);
  };

  const handleTableChange = async (
    pagination = initialState,
    _filter = "",
    sorter = ""
  ) => {
    let order = "";
    let sortColumn = "";
    if (sorter) {
      if (sorter.order === "ascend") {
        order = "ASC";
        sortColumn = sorter?.column.sortKey;
      } else if (sorter.order === "descend") {
        order = "DESC";
        sortColumn = sorter?.column.sortKey;
      }
    }
    setLoading(true);
    const { current, pageSize } = pagination;
    try {
      const response = await fetchData({
        ...pagination,
        current: current,
        pageSize: pageSize,
        sortBy: sortColumn,
        sortOrder: order,
      });
      const { data, total } = response;
      setData(data);
      setPagination({
        ...pagination,
        total: total,
        current: current,
        pageSize: pageSize,
        sortBy: sortColumn,
        sortOrder: order,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return { handleTableChange, data, loading, pagination, refetch };
};
