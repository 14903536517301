import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "../components/auth";
import { ROUTES } from "../constants/routes";
import ContentManagement from "../pages/ContentManagement";
import FeedbackManagement from "../pages/FeedbackManagement";
import ViewFeedback from "../pages/FeedbackManagement/View";
import TermsAndConditions from "../pages/ContentManagement/TermsAndConditions";
import Faqs from "../pages/ContentManagement/Faqs";
import AddFaq from "../pages/ContentManagement/Faqs/Add";
import EditFaq from "../pages/ContentManagement/Faqs/Edit";
import ContactUs from "../pages/ContentManagement/ContactUs";
import TagsManagement from "../pages/TagsManagement";
import ViewTag from "../pages/TagsManagement/View";
import PrivacyPolicy from "../pages/ContentManagement/PrivacyPolicy";
import CommunityGuidelines from "../pages/ContentManagement/CommunityGuidelines";
import BetaTesting from "../pages/ContentManagement/BetaTesting";
import Dashboard from "../pages/Dashboard";
import ApiLimit from "../pages/ApiLimit";
import ErrorPage from "../pages/ApiLimit/errorPage";
import MapType from "../pages/MapType";

// Layout
const Layout = lazy(() => import("../components/layout"));

// Auth
const Login = lazy(() => import("../pages/Authentication/Login"));
const ForgotPassword = lazy(() =>
  import("../pages/Authentication/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("../pages/Authentication/ResetPassword")
);
const ChangePassword = lazy(() =>
  import("../pages/Authentication/ChangePassword")
);

// User Management
const UserManagement = lazy(() => import("../pages/UserManagement"));
const ViewUser = lazy(() => import("../pages/UserManagement/View"));

// Profile Detail
const ProfileDetail = lazy(() => import("../pages/ProfileDetail"));

const MyAccount = lazy(() => import("../pages/MyAccount"));

const Routing = () => {
  return (
    <Routes>
      <Route path={ROUTES.login} element={<Login />} />
      <Route path={ROUTES.forgotPassword} element={<ForgotPassword />} />
      <Route path={ROUTES.resetPassword} element={<ResetPassword />} />
      <Route
        path={ROUTES.default}
        element={
          <AuthGuard>
            <Layout />
          </AuthGuard>
        }
      >
        <Route path={ROUTES.changePassword} element={<ChangePassword />} />
        <Route
          index
          path={ROUTES.userManagament}
          element={<UserManagement />}
        />
        <Route index path={ROUTES.dashboard} element={<Dashboard />} />

        <Route index path={ROUTES.viewUser} element={<ViewUser />} />
        <Route index path={ROUTES.viewFeedback} element={<ViewFeedback />} />
        <Route index path={ROUTES.terms} element={<TermsAndConditions />} />

        <Route index path={ROUTES.privacyPolicy} element={<PrivacyPolicy />} />

        <Route
          index
          path={ROUTES.communityGuidelines}
          element={<CommunityGuidelines />}
        />

        <Route index path={ROUTES.betaAgreement} element={<BetaTesting />} />

        <Route index path={ROUTES.faq} element={<Faqs />} />
        <Route index path={ROUTES.addFaq} element={<AddFaq />} />
        <Route index path={ROUTES.editFaq} element={<EditFaq />} />
        <Route index path={ROUTES.apiLimit} element={<ApiLimit />} />
        <Route index path={ROUTES.errorPage} element={<ErrorPage />} />

        <Route index path={ROUTES.contactUs} element={<ContactUs />} />

        <Route index path={ROUTES.viewFeedback} element={<ViewFeedback />} />

        <Route index path={ROUTES.tags} element={<TagsManagement />} />
        <Route index path={ROUTES.viewTag} element={<ViewTag />} />

        <Route index path={ROUTES.profile} element={<ProfileDetail />} />
        <Route index path={ROUTES.cms} element={<ContentManagement />} />
        <Route index path={ROUTES.feedback} element={<FeedbackManagement />} />
        <Route path={ROUTES.myAccount} element={<MyAccount />} />
        <Route path={ROUTES.mapType} element={<MapType />} />
        <Route
          path={ROUTES.default}
          element={<Navigate replace to={ROUTES.dashboard} />}
        />
      </Route>
      <Route path="*" element={<Navigate replace to={ROUTES.default} />} />
    </Routes>
  );
};

export default Routing;
